
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useQuery, useResult } from "@vue/apollo-composable";
import { GetDepositSummaries } from "@/modules/earnings/queries";
import { DateTime } from "luxon";
import { proxyApi } from "@/http/http.config";
import { encodeWithParam } from "@/util/DataEncodeDecode";

export default defineComponent({
  name: "Summaries",
  setup() {
    const route = useRoute();
    let depositSummaries: any = ref([]);
    const params = route.params;
    let clientName: any = ref("");
    onMounted(() => {
      getDepositSummaries();
    });
    const getDepositSummaries = ()=>{
      proxyApi.post('/getDepositSummaryByClient', encodeWithParam({clientID:route.query["clientID"]})).then(({data}) => {
        data.result.forEach((item: any) => {
        depositSummaries.value.push(item);
      });
      clientName.value = data.result[0].clientName;
      })
    };
    return {
      depositSummaries,
      clientName,
      params,
    };
  },
  methods: {
    goToDetails(depositSummary: string) {
      this.$router.push(`/earnings/deposit-summary/${depositSummary}`);
    },
    formatDate(date: string) {
      return DateTime.fromISO(date).toFormat("d LLL y");
    },
  },
});


import { defineComponent } from "vue";
import { IconUpload, BaseModal, IconWarningOctagon } from "@/components";
import micromodal from "micromodal";
import Loader from "@/components/modals/Loader.vue";
export default defineComponent({
  name: "UploadModal",
  components: { BaseModal, IconUpload, Loader, IconWarningOctagon },
  emits: ["file-selected", "open-camera"],
  props: {
    id: String,
    title: String,
    instructions: String,
    fileLabel: String,
    fileId: String,
    retryMessage: Boolean,
    displayLoader: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data() {
    return {
      isCameraOpen: false,
      isPhotoTaken: false,
      loading: false,
      sizeError: false,
      formatError: false,
    };
  },
  computed:{
    showLoader() {
      return this.$props.displayLoader;
    }
  },
  methods: {
    closeModal() {
      micromodal.close(this.$props.id);
    },
    fileSelected(event: any) {
      const input: HTMLInputElement = event.target;
      this.formatError = false;
      this.sizeError = false;
      if (input.files === null || input.files.length !== 1) {
        return;
      }
      this.loading = true;
      let acceptedFormat = ["jpg", "png", "jpeg", "gif"];
      let fileFormat = input.files.item(0)?.name.split(".")[1].toLowerCase();
      let format = acceptedFormat.some((o) => fileFormat?.includes(o));
      let fileSize: number = input.files.item(0)?.size || 0;
      if (fileSize < 5242880 && fileSize && format) {
        setTimeout(() => {
          this.$emit("file-selected", {
            fileId: this.$props.fileId,
            file: input.files!.item(0),
          });
          //this.closeModal();
          this.loading = true;
          event.target.value = "";
        }, 1000);
      } else {
        if (!format) {
          this.formatError = true;
        } else {
          this.sizeError = true;
        }
        this.loading = false;
      }
    },
    openCamera() {
      this.isCameraOpen = true;
      const constraints = {
        audio: false,
        video: true,
      };

      navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
        const videoPlayer = document.querySelector("video")!;
        videoPlayer.srcObject = stream;
      });
    },
    stopCapture() {
      let stopPlay = document.querySelector("video")!.srcObject;
      let tracks = (stopPlay as MediaStream).getTracks();
      tracks.forEach((track) => {
        track.stop();
      });
      this.isCameraOpen = false;
    },
    canvasImage() {
      return new Promise((resolve, reject) => {
        const liveCapture = document.querySelector("video")!;
        const previewContext = document.querySelector("canvas")!;
        const context = previewContext.getContext("2d")!;
        context.imageSmoothingQuality = "high";
        context.drawImage(liveCapture, 0, 0, 450, 337);
        resolve(previewContext.toDataURL("image/jpeg", "high"));
      });
    },
    captureImage() {
      this.canvasImage()
        .then((data) => {
          const file = this.dataURLtoFile(
            data,
            `Insurance-${this.$props.fileId}`
          );
          this.$emit("file-selected", {
            fileId: this.$props.fileId,
            file: file,
          });
        })
        .finally(() => {
          this.stopCapture();
          this.closeModal();
        });
    },
    dataURLtoFile(dataUrl, fileName: string) {
      const arr = dataUrl.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], fileName, { type: mime });
    },
  },
  // setup(props, { emit }) {
  //   const isCameraOpen = ref(false)
  //   const camera = ref()
  //   const closeModal = () => {
  //     micromodal.close(props.id);
  //   };

  //   const fileSelected = (event: any) => {
  //     const input: HTMLInputElement = event.target;
  //     if (input.files === null || input.files.length !== 1) {
  //       return;
  //     }
  //     closeModal();
  //     emit("file-selected", {
  //       fileId: props.fileId,
  //       file: input.files.item(0),
  //     });
  //   };

  //   const openCamera = () => {
  //     //const that = this
  //     isCameraOpen.value = true
  //     const constraints = {
  //       audio: false,
  //       video: true
  //     };

  //     navigator.mediaDevices
  //       .getUserMedia(constraints)
  //       .then(stream => {
  //         that.camera1.value.srcObject = stream;
  //       })
  //       .catch(error => {
  //         console.log(error)
  //       });
  //   }

  //   return { closeModal, fileSelected, openCamera, isCameraOpen, };
  // },
});

<template>
    <div class="icon">
        <svg width="24" height="24" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.13907 1.95695C6.52614 1.30192 7.47386 1.30193 7.86093 1.95695L12.6085 9.99127C13.0024 10.6579 12.5219 11.5 11.7476 11.5H2.25245C1.47815 11.5 0.997613 10.6579 1.39152 9.99127L6.13907 1.95695Z" stroke="white" stroke-linejoin="round"/>
            <path d="M6.5 4H7.5V8H6.5V4Z" fill="white"/>
            <path d="M6.5 9H7.5V10H6.5V9Z" fill="white"/>
        </svg>
    </div>
</template>
  
  <script>
  import { defineComponent } from "vue";
  
  export default defineComponent({
    name: "IconSupportTriangle",
  });
  </script>
export default [
  {
    path: "/login",
    name: "ca-login",
    component: () => import("@/modules/ca/login.vue"),
    meta: { title: "Delivery Drivers Inc | Login", noHeader: true, open: true, layout: "account" },
  },
  // {
  //   path: "/forgot-password",
  //   name: "forgotPassword",
  //   component: () => import("@/modules/login/ForgotPassword.vue"),
  //   meta: {
  //     title: "Delivery Drivers Inc | Forgot Password",
  //     noHeader: true,
  //     open: true,
  //     layout: "account"
  //   },
  // },
  // {
  //   path: "/account-locked",
  //   name: "accountLocked",
  //   component: () => import("@/modules/login/AccountLocked.vue"),
  //   meta: {
  //     title: "Delivery Drivers Inc | AccountLocked",
  //     noHeader: true,
  //     open: true,
  //     layout: "signup"
  //   },
  // },
  
  {
    path: "/reset",
    name: "updatePassword",
    component: () => import("@/modules/login/ChangePassword.vue"),
    meta: { title: "Delivery Drivers Inc | Reset", noHeader: true, open: true, layout: "account" },
  },
  // {
  //   path: "/forgot-username",
  //   name: "forgotUsername",
  //   component: () => import("@/modules/login/ForgotUsername.vue"),
  //   meta: {
  //     title: "Delivery Drivers Inc | Forgot Username",
  //     noHeader: true,
  //     open: true,
  //     layout: "account"
  //   },
  // },
  {
    path: "/reset-password",
    name: "resetPassword",
    component: () => import("@/modules/login/ResetPassword.vue"),
    meta: {
      title: "Delivery Drivers Inc | Reset Password",
      noHeader: true,
      open: true,
      layout: "account"
    },
  },

  {
    path: "/otp-verify",
    name: "otpVerify",
    component: () => import("@/modules/login/OtpVerify.vue"),
    meta: {
      title: "Delivery Drivers Inc | Otp Verify",
      noHeader: true,
      open: true,
      layout: "account"
    },
  },
  {
    path: "/password-success",
    name: "passwordSuccess",
    component: () => import("@/modules/login/PasswordChangeSuccess.vue"),
    meta: {
      title: "Delivery Drivers Inc | Success",
      noHeader: true,
      open: true,
      layout: "account"
    },
  },
  {
    path: "/link-expired",
    name: "linkExpired",
    component: () => import("@/modules/login/PasswordChangeExpired.vue"),
    meta: {
      title: "Delivery Drivers Inc | Expired",
      noHeader: true,
      open: true,
      layout: "account"
    },
  },

  {
    path: "/sessionexpired",
    name: "sessionexpired",
    component: () => import("@/modules/login/SessionExpired.vue"),
    meta : {
      title: "Delivery Drivers Inc | Session Expired",   
      noHeader : true,
      open:true
    }
  },
];

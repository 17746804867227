
  import { defineComponent, ref, onMounted } from "vue";
  import { state } from "@/harlem/stores";
  import { proxyApi } from "@/http/http.config";
  import { encodeWithParam } from "@/util/DataEncodeDecode";
  import checklistMethods from "../../signup/components/Checklist/Helpers/Index";
  import { IconArrowLeft, IconArrowRight } from "@/components";
  import { DateTime } from "luxon";
  import Loader from "@/components/modals/Loader.vue";
  import { gTagEvent } from "@/util/GoogleAnalytics";
  import { useRouter } from "vue-router";
  
  
  export default defineComponent({
    name: "DepositSummaryHome",
    components: { IconArrowLeft, IconArrowRight, Loader },
    setup() {
      const Filters: {
        LASTPAY: string;
        LAST30: string;
        LAST3M : string;
        LAST6M:string;
        YTD: string;
        ALL: string;
        LE:string;
      } = {
        LASTPAY: "last_pay",
        LAST30: "last_30",
        LAST3M:"Last_3_months",
        LAST6M:"Last_6_months",
        YTD: "year_to_date",
        ALL: "all_time",
        LE:"last_earning"
      };
      const router = useRouter();
      const selectedOption = ref("all_time");
      const isLoading = ref(false);
      const currentFilter: any = ref(Filters.ALL);
      const dropdownFilters = ref([
        {
          label: "Last earnings",
          value : "last_earning"
        },
        {
          label: "Last 30 days",
          value: "last_30",
        },
        {
          label: "Last 3 months",
          value: "Last_3_months",
        },
        {
          label:"Last 6 months",
          value: "Last_6_months",
        },
        {
          label: "Year to date",
          value: "year_to_date",
        },
        {
          label: "All time",
          value: "all_time",
        },
      ]);
      const isEmpty = ref(true);
      const depositSummaries: any = ref([]);
  
      onMounted(() => {
        getEarnings({ startDate: "2010-01-01" });
        gTagEvent("View Deposit Summary Page", "Partner Dashboard", `partner_id: ${state.user.partnerId}`)
      });
      //Build test ignore this comment
      const setFilter = (event) => {
        let date = new Date();
        if (event.target.value === "last_pay")
          date.setMonth(date.getMonth() - 240);
        else if (event.target.value === "last_30")
          date.setMonth(date.getMonth() - 1);
        else if (event.target.value === "Last_3_months")
         date.setMonth(date.getMonth() - 3);
        else if (event.target.value === "Last_6_months")
         date.setMonth(date.getMonth() - 6);
        else if (event.target.value === "year_to_date")
          date.setMonth(date.getMonth() - 12);
        else if (event.target.value === "all_time")
          date.setMonth(date.getMonth() - 240);
        else if (event.target.value === "last_earning")
         date.setMonth(date.getMonth() - 1)
        const formatteddate = date.toISOString().slice(0, 10);
        currentFilter.value = event.target.value;
        getEarnings({startDate: formatteddate });
      };
  
      const earningsData = (earnings) => {
        console.log(earnings)
        depositSummaries.value = [];
        isEmpty.value = earnings.length == 0 ? true : false;
        if (currentFilter.value == "last_pay") {
          let summary;
         
            summary = {
              payPeriod: `${formatDate(earnings[0].startDate)} - ${formatDate(earnings[0].endDate)}`,
              amount: earnings[0].amount,
              depositSummaryID: "",
              paymentProcessor: 'PAYFARE',
              startDate:earnings[0].startDate,
              endDate:earnings[0].endDate,
              disbursmentDate: earnings[0].disbursement_date
          };
          
          
          depositSummaries.value.push(summary);
       } else {
  
          earnings.map((item)=>{
            let summary;
           
              summary = {
                payPeriod: `${formatDate(item.startDate)} - ${formatDate(item.endDate)}`,
                amount: item.amount,
                depositSummaryID: "",
                paymentProcessor: 'PAYFARE',
                startDate:item.startDate,
                endDate:item.endDate,
                disbursmentDate: item.disbursement_date
          };
           
             
            if(Object.keys(summary).length) {
              depositSummaries.value.push(summary);
            }
            
          })
        }
        isLoading.value = false;
      };
  
      const getEarnings = (date) => {
        isLoading.value = true;
        proxyApi
          .post("/getDriversEarnings", encodeWithParam(date))
          .then(({ data }) => {
            earningsData(data.result);
          })
          .catch((e: any) => {
            isLoading.value = false;
          });
      };
  
      const formatDate = (date) => {
        return DateTime.fromFormat(date, "yyyy-MM-dd").toFormat("dd MMM yyyy");
      }
      const formatDatePacific = (date) => {
        return DateTime.fromFormat(date, "yyyy-MM-dd").setZone("America/Los_Angeles").toFormat("dd MMM yyyy");
      }
      const formatDatePacificURL = (date) => {
        return DateTime.fromFormat(date, "yyyy-MM-dd").setZone("America/Los_Angeles").toFormat("yyyy-MM-dd");
      }
  
      const viewSummaries =(summary)=>{
        router.push({name:"ca-deposit-summary",query:{startDate:summary.startDate,endDate:summary.endDate,disbursmentDate:summary.disbursmentDate}}) 
      }
  
      return {
        setFilter,
        currentFilter,
        Filters,
        depositSummaries,
        isEmpty,
        selectedOption,
        dropdownFilters,
        isLoading,
        viewSummaries
      };
    },
    methods: {
      formatCurrency(amount, space) {
        amount == undefined || isNaN(amount) ? (amount = 0) : amount;
        if (!space) {
          return new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(amount);
        } else {
          const formattedMoney = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(amount);
          return `${formattedMoney.substring(0, 1)} ${formattedMoney.substring(
            1,
            formattedMoney.length
          )}`;
        }
      },
      isMobile() {
        return checklistMethods.isMobile();
      },
    },
  });
  

import {defineComponent , onMounted , ref , watchEffect } from "vue";
import { Form, Field } from "vee-validate";
import { AchAccountType  } from "@/modules/signup/directDeposit";
import { TextFieldValidated } from "@/components"
import { proxyApi, restApi, kycApi } from "@/http/http.config";
import { decrypt, encodeWithParam, encrypt } from "@/util/DataEncodeDecode";
import { useRoute, useRouter } from "vue-router";
import checklistMethods from "../components/Checklist/Helpers/Index";
import InitChecklist from "../components/Checklist/InitChecklist.vue";
import  AccountPop  from "../signup/AccountPop.vue";
import micromodal from "micromodal";


export default defineComponent({
    name : 'PersonalDirectDeposit',
    components : {
    Form,
    // AchAccountCategory,
    InitChecklist,
    TextFieldValidated,
    AchAccountType,
    Field,
    AccountPop
    
    // AchBankNumber
},
data(){
  return{
    // bankName:"",
    // transitNum : "",
    // confirmTransitno:"",
    name : "",
    // selectedOption:"a",
    //accNumber:""
    // transitCheck:"",
    // accountCheck:"",
    // communicationEmail: "",
    // accountNoCheck:false,
    // transitNoCheck:false,    
  }
},
setup() { 
  const transitCheck = ref("");
  const transitNoCheck = ref(false);
  const bankError = ref(false);
  const accountCheck = ref("");
  const accountNoCheck = ref(false);
  const isSubmitDisabled = ref(true);
  const router = useRouter();
  const accNumber = ref("");
  const originalValue = ref("");
  const originalTransit = ref("");
  const transitNumber=ref("");
  const bankID = ref("");
  const confirmTransitValue = ref("");
  const checkBox=ref(false);
  const emailValidationMessage=ref("");

  const maskingValues = () =>{
    if(accNumber.value.length==7){
      if(!accNumber.value.includes("*")){
        originalValue.value = accNumber.value;
      }
      
      accNumber.value = '*'.repeat(accNumber.value.length - 3) + accNumber.value.slice(accNumber.value.length -3);
      checkthis(originalValue.value);
    } else if( accNumber.value.length > 7  && accNumber.value.length <= 12){
      if(!accNumber.value.includes("*")){
        originalValue.value = accNumber.value;
      }
      accNumber.value = '*'.repeat(accNumber.value.length - 4) + accNumber.value.slice(accNumber.value.length -4);
      checkthis(originalValue.value);
      
    } else if(accNumber.value ==''){
      originalValue.value=''
    } else if(accNumber.value.length<7 || accNumber.value.length>12){
      originalValue.value = accNumber.value;
      accountNoCheck.value=true
    }
    let accountMain = document.querySelector('input[name="cAccountNumber"]') as HTMLInputElement;
    let accountOne = document.querySelector('input[name="AccountNumber"]') as HTMLInputElement;
    if(accountMain.value == ''&& accountOne.value==''){
          accountCheck.value = '';
          accountNoCheck.value=false
        }
  }
  function checkthis(accountValue){
    let cField=document.querySelector('input[name="cAccountNumber"]') as HTMLInputElement;
    if(accountValue != cField.value){
      accountCheck.value = "Account numbers don’t match."
    }
    
  }
  function showMaskedBankNumber(event) {
    accNumber.value = originalValue.value
    }
    function showTransNo(event){
      transitNumber.value = originalTransit.value

    }
  const maskingTransit = ()=>{
    if(transitNumber.value.length == 5){
      originalTransit.value = transitNumber.value
      transitNumber.value = '*'.repeat(transitNumber.value.length - 2) + transitNumber.value.slice(transitNumber.value.length - 2)
    }else if(transitNumber.value==''){
      originalTransit.value=''
    }else if(transitNumber.value.length !=5){
      originalTransit.value = transitNumber.value
    }

  }

  let communicationEmail = ref<boolean | string | null>(false); 
  let accountHolderName = ref<boolean | string | null>(false);
  const bankidCheck= (e) =>{
    bankID.value = e.target.value;
    let banknoRegex = /^\d{3}$/;
    if(!banknoRegex.test(e.target.value)){
      bankError.value = true;
    }else{
      bankError.value = false;
    }
  }
  const forTransitNo = (e)=>{
    let transitMain = document.querySelector('input[name="cTransitNo"]') as HTMLInputElement;
    let transitMainval = document.querySelector('input[name="TransitNo"]') as HTMLInputElement;
    transitNumber.value = e.target.value;
    let transitRegex = /^\d{5}$/;
    if (!transitRegex.test(e.target.value)){
      transitNoCheck.value = true;
    } else {
      transitNoCheck.value = false;
    }

    if(transitMain.value !=''){
      if(e.target.value !=transitMain.value){
        transitCheck.value = "Transit numbers don’t match."

      }else transitCheck.value = ""
      
    }
    if(transitMain.value == '' && transitMainval.value==''){
      transitCheck.value = '';
      transitNoCheck.value=false;
    }

  }
  const forAccountNo = (e) =>{
    let accountMain = document.querySelector('input[name="cAccountNumber"]') as HTMLInputElement;
    accNumber.value = e.target.value;
    let accountOne = document.querySelector('input[name="AccountNumber"]') as HTMLInputElement;
    let accountRegex = /^\d{7,12}$/;
    if(!accountRegex.test(e.target.value)){ 
      if(accountMain.value != ''&& accountOne.value!=''){
        accountNoCheck.value = true;
      }

    } else {
      accountNoCheck.value= false;
    }

    if(accountMain.value !=''){
      if(e.target.value !=accountMain.value){
        if(accountMain.value != ''&& accountOne.value!=''){
          accountCheck.value = "Account numbers don’t match."
        }
        

      }else accountCheck.value=''
    }
    if(accountMain.value == ''&& accountOne.value==''){
          accountCheck.value = ''
        }
  }

  const accountMatch = (e) => {
    let accountMainval = document.querySelector('input[name="AccountNumber"]') as HTMLInputElement;
    let accountOne = document.querySelector('input[name="cAccountNumber"]') as HTMLInputElement;
    if(e.target.value != originalValue.value){
      if(accountMainval.value != '' && accountOne.value!=''){
         accountCheck.value = "Account numbers don’t match."
      }
      
      
    }else{
       accountCheck.value = ""
    }

  }
  const transitMatch = (e) =>{
    confirmTransitValue.value = e.target.value;
    // let transitMain = document.querySelector('input[name="TransitNo"]') as HTMLInputElement;
    if(e.target.value != originalTransit.value){
      return transitCheck.value = "Transit numbers don’t match."
    }else{
      return transitCheck.value = ""
    }
    

  }
  const editEmail = (e)=>{
      let regex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
      let start = /^([ ]+)/;
      let end = /([ ]+$)/;
      let space = /\s/;
      let ending = /^.*\.com$/;
      let startingchar = /^[^\W_].*/;
      if(e.target.value.length === 0) {
         return emailValidationMessage.value="Email cannot be blank"
      } 
      if (!regex.test(e.target.value)) {
        return emailValidationMessage.value="Not a valid email address"
      }
      if (start.test(e.target.value) || end.test(e.target.value) || space.test(e.target.value)) {
        return emailValidationMessage.value = "Email cannot include spaces"
      }
      if(!ending.test(e.target.value) || !startingchar.test(e.target.value)){
        return emailValidationMessage.value = "Not a valid Email address"
      }
      return emailValidationMessage.value=""
  }
  watchEffect(() => {
    if(accountNoCheck.value || accountCheck.value || transitCheck.value || transitNoCheck.value || !accNumber.value || !transitNumber.value || !bankID.value || !confirmTransitValue.value || bankError.value || !checkBox.value || emailValidationMessage.value){
      isSubmitDisabled.value = true;
    }else{
      isSubmitDisabled.value = false;
    }

  })
  
  const saveAcountDetails = async (data: any) => {  
    let flag = false;
    if (sessionStorage.getItem('referer') == 'profile'){
      flag = true;
    }
    let obj = {
    account_number: data.cAccountNumber,
    institution_number: data.bankID,
    transit_number: data.cTransitNo,
    reset_flag : flag
    }
    if(!isSubmitDisabled.value){
      proxyApi.post("/createPayAccount", encodeWithParam(obj))
        .then(async ({ data }) => {
          if (sessionStorage.getItem('referer') == 'profile'){
            sessionStorage.setItem('referer',"");
            return router.push({
                name: "ca-earnings-account-home",
              });
          }
          if(data.success){
          return router.push({
                name: "accountProcessing",
              });
            }else{
              return router.push({
                name: "accountProcessing", // change this for error scenario
              });
            }
        })
    }

     
    };
    onMounted(() => {
      communicationEmail.value =localStorage.getItem("comm_email");
      const accountHolderNameFromSession = sessionStorage.getItem("ddi:user");
  if (accountHolderNameFromSession) {
    const userData = JSON.parse(accountHolderNameFromSession);
    if (userData.firstName ) {
      accountHolderName.value = userData.firstName + " " + userData.lastName;
    }
  }
  });
  // let data = sessionStorage.getItem("ddi:user"); console.log(JSON.parse(data))


    return {
      saveAcountDetails,
      communicationEmail,
      accountHolderName,
      transitMatch,
      transitCheck,
      transitNoCheck,
      forTransitNo,
      accountMatch,
      accountCheck,
      forAccountNo,
      accountNoCheck,
      isSubmitDisabled,
      accNumber,
      transitNumber,
      bankID,
      bankidCheck,
      bankError,
      confirmTransitValue,
      checkBox,
      editEmail,
      emailValidationMessage,
      maskingValues,
      maskingTransit,
      showMaskedBankNumber,
      showTransNo,
      micromodal
      // resetNumbers,
      // showAccNo
    }
},

methods:{
  isMobile() {
      return checklistMethods.isMobile();
    },
}

})

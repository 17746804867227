
import "../../assets/css/components/modals.scss"
import { defineComponent, onMounted, reactive } from "vue";
import { proxyApi } from "@/http/http.config";

export default defineComponent({
  name: "TaxConfirmAddress",
  emits: ['confirm'],
  setup() {
    const address = reactive({
      address1: '',
      address2: '',
      city: '', 
      county: '',
      state: '',
      postalCode: '',
    })

    onMounted(() => {
      proxyApi.get('/getPersonalDetails').then(({data}) => {
        address.address1 = data.result[0].address1
        address.address2 = data.result[0].address2
        address.city = data.result[0].city
        address.county = data.result[0].county
        address.state = data.result[0].state
        address.postalCode = data.result[0].postalCode
      })
    })
    return {
      address,
    }
  },
  methods: {
    edit() {
      this.$emit('confirm', 'edit')
    },
    confirm() {
      this.$emit('confirm', 'confirm')
    }
  },
});

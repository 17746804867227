
import { defineComponent, onMounted, ref } from "vue";
import { useQuery, useResult } from "@vue/apollo-composable";
import { GetProfileDetails, GET_PARTNER_ID } from "@/modules/profile/queries";
import { useRoute } from "vue-router";
import { mutation } from "@/harlem/mutation";
import { state } from "@/harlem/stores";
import { getter } from "@/harlem/getter";
import { datadogRum } from "@datadog/browser-rum";
import ContactSupport from "./ContactSupport.vue";
import IconSparkDriverLogo from "./icons/IconSparkDriverLogo.vue";
export default defineComponent({
  name: "PortalHeader",
  emits: ["menuopen"],
  components: {IconSparkDriverLogo,ContactSupport},
  setup() {
    const route = useRoute();

    
    let flag = ref(true);
    let partnerID = ref("");
    let data:any =sessionStorage.getItem("ddi:mergeTags");
    let fullName = JSON.parse(data);
    let locationData:any =sessionStorage.getItem("ddi:user");
    let locationDetails = JSON.parse(locationData);
    let locationName = locationDetails.locationName;
    const paymentAgreementComplete = ref(false)

    if (route.meta.step === "signupStage") {
      flag.value = false;
    }

    // const { result: userDetails, onResult: setPartnerID } = useQuery( GET_PARTNER_ID,{username: localStorage.getItem("username")?.toLowerCase(),},{ fetchPolicy: "no-cache" });
    // const { result: profileResult, onResult: setProfileData, refetch: refetchPartnerId, } = useQuery(   GetProfileDetails,  { id: partnerID },{ fetchPolicy: "no-cache" });
    // setPartnerID(({ data }) => {
    //   if (data.users[0].partners[0].id) {
    //     partnerID.value = data?.users[0]?.partners[0]?.id;
    //   } else {
    //     refetchPartnerId();
    //   }

    //   // console.log(data.users[0].partners[0].id)
    // });

    // setProfileData(({ data }) => {
    //   const partner = data?.partners[0];
    //   datadogRum.setUser({
    //     id: partner?.id,
    //     name: `${partner?.firstName} ${partner?.lastName}`,
    //     username: partner?.username,
    //   });
    //   mutation.user.setPartnerId(partner?.id);
    //   mutation.user.setEmail(partner?.email);
    //   mutation.user.setUserId(partner?.userID);
    //   mutation.user.setFirstName(partner?.firstName);
    //   mutation.user.setLastName(partner?.lastName);
    //   mutation.user.setLocationId(
    //     partner?.locations[0]?.location.id
    //       ? partner.locations[0].location.id
    //       : ""
    //   );
    //   mutation.user.setLocationName(
    //     partner?.locations[0]?.location.name
    //       ? partner.locations[0].location.name
    //       : ""
    //   );
    //   mutation.user.setLocationPartnerId(partner?.locations[0]?.id ? partner.locations[0]?.id : "");
    //   mutation.user.setClientId(partner?.locations[0]?.location.clientID ? partner.locations[0].location.clientID : "");
    // });

    // const profileData: any = useResult(
    //   profileResult,
    //   {},
    //   (data) => data.partners[0]
    // );

    // const overrideText = () => {
    //   switch (route.name) {
    //     case "inprogress":
    //       return "Unfinished Applications";
    //     case "profile":
    //       return "Profile";
    //     case "profileEdit":
    //       return "Edit Profile";
    //     case "earnings":
    //       return "Earnings";
    //     case "depositSummary":
    //     case "document":
    //       return "Document";
    //     case "dashboardDocuments":
    //       return "Documents";
    //     case "signedDocuments":
    //       return "Contractual Documents";
    //     case "taxDocuments":
    //       return "Tax Documents";
    //     case "personalDocuments":
    //       return "Personal Documents";
    //     default:
    //       return null;
    //   }
    // };
    onMounted(() => {
      let element1 = document.getElementById('profileImage');
      element1?.append(fullName?.partner?.firstName.substring(0, 1).toUpperCase() +  fullName?.partner?.lastName.substring(0,1).toUpperCase());
      paymentAgreementComplete.value = state.payment.paymentAgreementComplete
    });

    localStorage.setItem('theme', 'ddi');
    return { state, getter,fullName, locationName, flag, route};
    
  },
  methods: {
    openMenu() {
      this.$emit("menuopen");
    },
    
  },
  
});

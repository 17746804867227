
import { defineComponent, ref, watch, onMounted } from "vue";
import { IconSupport } from "@/components";
import { useRoute } from "vue-router";
import router from "@/router";
import ContactSupportModal from "./modals/ContactSupportModal.vue";
import IconSupportTriangle from "./icons/IconSupportTriangle.vue";
import IconThumbsUp from "./icons/IconThumbsUp.vue";

export default defineComponent({
  name: "ContactSupport",
  components: { IconSupport, ContactSupportModal, IconSupportTriangle, IconThumbsUp },
  setup() {
    let currentRoute = useRoute();
    const showContactForm = ref(false);
    const showSnackBar = ref(false);
    const isAuthenticated = !!localStorage.getItem("access_token");

    const loginPages = () => {
      const { path } = currentRoute
      const isLoginPages = (path == "/login" || path == "/" || path.substring(0, 7) == "/forgot" || path.substring(0, 6) == "/reset" || path == "/password-success" || path == "/link-expired" || path == "/otp-verify")
      return isLoginPages
    }

    const faqPages = () => {
      const { path } = currentRoute
      return path == "/ca-faq"
    }

    const onboardingPages = () => {
      const { path } = currentRoute
      const isOnboardingPages = (path == "/signup" || path == "/signup/verification")
      return isOnboardingPages
    }

    const isShownInPage = () => {
      return (loginPages() || onboardingPages()) == false
    }

    function onClickSupport(){
      ((currentRoute.path === "/ca-faq") || checkDmsId()) ? openContactPopUp(): openFaqPage();
    }
    
    function checkDmsId(){
      return sessionStorage.getItem('payeeID') !== 'null' ? true : false;
    }

    function openFaqPage(){
      router.push({
        name:"ca-faq"
      });        
    }
    function openContactPopUp(){
      document.body.style.overflow = "hidden";
      showContactForm.value = true
    }
    function closeContactPopUp(data:any){
      document.body.style.overflow = "auto";
      if(data.value) toggleSnackBar();
      showContactForm.value = false;
    }

    function toggleSnackBar(){
      showSnackBar.value = true;
      setTimeout(()=>{
        showSnackBar.value = false;
      },3000)
    }

    return { onClickSupport, currentRoute, isAuthenticated, isShownInPage, faqPages, showContactForm, closeContactPopUp, openFaqPage, showSnackBar }
  },
})

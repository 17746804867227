
  import { defineComponent, ref, computed, onMounted, reactive } from "vue";
  
  export default defineComponent({
    name: "kycPendingManual",
    components: {
      
    },
   methods: {
    redirectTONext: function(){
      return this.$router.push({
                  name: "ca-personalInfo",
                });
    },
   }
  });
  
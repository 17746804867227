
 import { IconArrowLeft , IconArrowRight } from "@/components";
import { defineComponent, ref, onMounted, computed } from "vue";
import { proxyApi } from "@/http/http.config";
import { encodeWithParam } from "@/util/DataEncodeDecode";
import checklistMethods from "../../signup/components/Checklist/Helpers/Index";
import { useRoute, useRouter } from 'vue-router'
import micromodal from "micromodal";
import { DateTime } from "luxon";
import {
  TaxConsent,
  TaxConfirmAddress,
  WalmartTaxConsentPolicy,
  OneNotEligible,
} from "@/components";
import { gTagEvent } from "@/util/GoogleAnalytics";
import { state } from "@/harlem/stores";
// import FunnelStatus from "../../funnelStatus/FunnelStatusWidget.vue";
import { getHiddenEarningAccounts, getOneStatus } from "../../funnelStatus/Helpers";
// import { disableEarningsTab } from "@/router/Helpers";

export default defineComponent({
  name: "ca-earnings-account",
  components: {
    OneNotEligible,
    TaxConsent,
    TaxConfirmAddress,
    WalmartTaxConsentPolicy,
    // FunnelStatus,
    IconArrowLeft,
    IconArrowRight
  },
  setup() {
    const firstName = ref(state.user.firstName);
    const partnerLocation = state.user.locationName;
    const router = useRouter();
    const route = useRoute();
    const allowTaxesDownload = ref(true);
    const pendingNumber = ref(1);
    const unfinishedNumber = ref(0);
    const docsToSign = ref(false);
    const unfinished = ref(false);
    const progressStage = ref(false);
    const onePaymentDate = DateTime.fromISO(state.user.oneStartDate).toFormat('MM/dd/yyyy')
    const oneStatus:any = ref(null);
    const garnishment = state.user.garnishment
    const oneIntegration = state.user.isOneIntegrated;
    const isOneActive = state.user.isActive;
    const payeeID = sessionStorage.getItem('payeeID')
    const oneSignUp = route.query.oneSignUp
    const branchStatus = ref("")
    const noActiveAccounts = ref(true)
    const isEarningsDisabled = ref(false)
    const attemptedEarningAccount = ref(false)
    const oneEnabled = ref(false)
    const onePaymentStarted = computed(() => {
      return DateTime.now().toFormat('MM/dd/yyyy') > onePaymentDate ? true : false;
    });

  

    const handleSubmit = (choice: string) => {
      micromodal.close('modal-tax-consent')
      proxyApi.post('/taxConsent', encodeWithParam({
        taxConsent: choice,
        signedOn: new Date(),
      }))
      if (choice === 'false') micromodal.show('tax-confirm-address')
    }

    const handleConfirm = (choice: string) => {
      micromodal.close('tax-confirm-address')
      if (choice === 'edit') router.push({ name: 'profile' })
    }

    const gTagONE = () => {
      gTagEvent("Dashboard Sent to Create ONE Account", "ONE", true)
    }

    const getBranchStatus = () => {
      proxyApi.get('/getWalletStatus').then(({ data }) => {
        if (data.result.length > 0) {
          const status = data.result[0]?.status
          branchStatus.value = status
        }
      })
    }

    const isAttemptedEarningAccount = async () => {
      attemptedEarningAccount.value = await getHiddenEarningAccounts()
    } 

    const showOneValuePropBanner = () => {
      let validONEStatus, hasPayeeID;
      validONEStatus = (['STARTED', '', undefined].includes(oneStatus.value));
      hasPayeeID = payeeID !== 'null';
      const showBanner = (validONEStatus && !garnishment && hasPayeeID) && oneEnabled.value == false
      return showBanner
    }

    onMounted(async () => {
      // if (oneSignUp) {
      //   micromodal.show('one-not-eligible')
      // }
      // proxyApi.get('/getTaxConfig').then(({ data }) => {
      //   allowTaxesDownload.value = JSON.parse(data.result[0].value).driverTaxes
      // })
      // proxyApi.get('/getIsOneOptionEnabled').then(({ data }) => {
      //   oneEnabled.value = data.result[0]?.show_one_pymt_provider
      // })
      // let progress: any = localStorage.getItem("progress")
      // let obj = JSON.parse(progress)
      
      // await setOneStatusRef()
      // await getBranchStatus()
      // await getActiveEarningAccounts()
      // await getIsEarningsDisabled();
      // await isAttemptedEarningAccount()
    })

    // const getActiveEarningAccounts = () => {
    //   proxyApi.get('/getActiveEarningAccounts').then(({data}) => {
    //     const message = data.message
    //     if (message == "No active accounts"){
    //       return 
    //       } else {
    //         noActiveAccounts.value = false;
    //       }
    //   })
    // }

    // const getIsEarningsDisabled = async () => {
    //   await disableEarningsTab().then((data) => {
    //     return isEarningsDisabled.value = data
    //   });
    // }

    // const setOneStatusRef = async () => {
    //   const status = await getOneStatus();
    //   oneStatus.value = status
    // }

    return {
      firstName,
      unfinishedNumber,
      pendingNumber,
      docsToSign,
      progressStage,
      unfinished,
      allowTaxesDownload,
      handleSubmit,
      handleConfirm,
      micromodal,
      oneStatus,
      garnishment,
      oneEnabled,
      oneIntegration,
      onePaymentStarted,
      isOneActive,
      payeeID,
      gTagONE,
      onePaymentDate,
      oneSignUp,
      router,
      isEarningsDisabled,
      branchStatus,
      showOneValuePropBanner,
      partnerLocation
    };
  },
  methods: {
    isMobile() {
      return checklistMethods.isMobile();
    },
  }
});

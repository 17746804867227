<template>
    <div id="faq-modal" class="modal" aria-hidden="true">
      <div class="modal-window_overlay policy" tabindex="-1">
        <div class="tax-consent-faq-modal modal-window_container">
          <div class="tax-consent-faq-modal-title">
            <p>FAQs</p>
            <svg @click="close" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 1L1 15M1 1L15 15" stroke="#8D95AA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div class="tax-consent-faq-modal-body">
            <div class="tax-consent-faq-modal-content" v-for="(row, id) in faq" v-bind:key="id">
              <div class="tax-consent-faq-modal-content-header">
                {{ row.question }}
              </div>
              <div class="tax-consent-faq-modal-content-text" v-html="row.answer" />
            </div>
            <div class="tax-consent-faq-modal-button" >
              <button class="button next-button" @click="close">OK</button>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </template>
  
  <script lang="js">
  import { defineComponent } from 'vue';
  import { proxyApi } from "@/http/http.config";
  import { encodeWithParam } from "@/util/DataEncodeDecode";


  export default defineComponent({
    name: 'FaqModal',
    props: {
      close: { 
        type: Function
      }
    },
    data() {
      return {
        dataFetched:false,
        faq: [],
      }
    },
    async mounted() {
      this.dataFetched = false;

      try {
        const resp = await proxyApi.post("/getFaqs", encodeWithParam({ groupId: 2 }));
        if(resp.data.success){
          this.faq = resp.data.result;
          this.dataFetched = true;
        }else{
          this.faq = [];
        }
      } catch (err) {
        this.faq = [];
        console.log("error: ", err);
      }
    },  
  })
  </script>
  
  <style scoped>
  .tax-consent-faq-modal {
    background-color: #fff;
    padding: 0 16px 16px 16px;
    width: 100%;
    height: 100%;
    max-width: 700px;
    height: 100%;
    border-radius: 0px 0px 0px 0px;
    box-sizing: border-box;
    overflow: hidden;
  }
  .tax-consent-faq-modal-title {
    height: 30px;
    margin: 24px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .tax-consent-faq-modal-title > p {
    font-size: 24px;
    font-weight: 600;
    color: #3B4459;
    margin-bottom: 0;
  }
  .tax-consent-faq-modal-body{
    width: 100%;
    height: calc(100% - 68px);
    overflow-y: scroll;
  }
  .tax-consent-faq-modal-content {
    margin-bottom: 18px;
  }
  
  .tax-consent-faq-modal-content-header {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    line-height: 24px;
    color: #3B4459;
  }
  
  .tax-consent-faq-modal-content-text {
    font-size: 18px;
    line-height: 24px;
    color: #6A7183;
  }
  
  .tax-consent-faq-modal-button {
    width: 240px;
    margin: auto;
    margin-top: 109px;
  }
  </style>

<style>
  .tax-consent-faq-modal-content-text {
    font-size: 18px;
    line-height: 24px;
    color: #6A7183;
  }
</style>
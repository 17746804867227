

import faqModal from "../faq/faqModal.vue";
import router from "@/router";
import { defineComponent } from "vue";
import { IconArrowLeft } from "@/components";
import MicroModal from "micromodal";

  
  export default defineComponent({
    name: "ca-settings",
    components: { IconArrowLeft, faqModal},
    setup() {
        function redirectToHome(){
            router.push({
                name:"ca-earnings-account-home"
            })
        }
        function openFaqModal(){
            MicroModal.show('faq-modal');
        }
        function closeFaqModal(e:MouseEvent){
            MicroModal.close('faq-modal');

        }
      return { redirectToHome, openFaqModal, closeFaqModal}
    },
  })
  

import {  defineComponent,ref , computed } from "vue";
import { Form , Field }  from "vee-validate";
import { signup, restApi, kycApi } from "@/http/http.config";
import { decrypt, encodeWithParam, encrypt } from "@/util/DataEncodeDecode";
import { useRoute, useRouter } from "vue-router";
import { IconEyeClosed , IconEyeOpen , FieldError } from "../../../components";

export default defineComponent({
    name: 'CreatePassword',
    props: ['email'],
    components : { Form, Field , FieldError , IconEyeClosed , IconEyeOpen},
    data(){
        return{
            forConfirm:true,
            regChecks:true,
        }
    },
    setup (props) {
        console.log(props.email , "setip")
        const router = useRouter();
        const passwordHidden = ref(true);
        const togglePassword = () => (passwordHidden.value = !passwordHidden.value);
        const viewPassword = computed(() =>{
            return passwordHidden.value ? "password" : "text";

        });
        const confirmPasswordHidden = ref(true);
        const toggleConfirmPassword = () => (confirmPasswordHidden.value = !confirmPasswordHidden.value);
        const viewConfirmPassword = computed(() =>{
            return confirmPasswordHidden.value ? "password" : "text" ;
        })

        const lengthCheck = ref(true);
        const NumCheck = ref(true);
        const upperPass = ref(true);
        const lowerPass = ref(true);
        const specialPass = ref(true);
        const matchFields = ref(true);

        const validatePassword = async (data: any) => { 
            // console.log(data.password)
            // console.log(props.email)
            signup.post('/setPassword', encodeWithParam({ email: props.email, password: data.password}))
            .then(async ({ data }) => {
                if(data.success === true){
                    return router.push({
                        name: "ca-login",
                    });
                    
                }
            })
        }

        return {
            passwordHidden,
            togglePassword,
            viewPassword,
            confirmPasswordHidden,
            toggleConfirmPassword,
            viewConfirmPassword,
            lengthCheck,
            NumCheck,
            upperPass,
            lowerPass,
            specialPass,
            validatePassword,
            matchFields
            
        }

    },
    methods: {
       
        passRequirement :function(e){

            const NumRegex = /\d/;
            const lowerRegex = /[a-z]/;
            const upperRegex = /[A-Z]/;
            const specialRegex = /[^0-9a-zA-Z\s'-]/
            if(e.target.value.length > 7 ){
                this.lengthCheck = false;
            }else{
                this.lengthCheck = true;
            }
        
            if(NumRegex.test(e.target.value)){
                this.NumCheck = false;
            }else{
                this.NumCheck = true;
            }
            if(upperRegex.test(e.target.value)){
                this.upperPass = false;
            }else{
                this.upperPass = true;
            }
            if(lowerRegex.test(e.target.value)){
                this.lowerPass = false;
            }else{
                this.lowerPass = true;
            }
            if(specialRegex.test(e.target.value)){
                this.specialPass = false;
            }else{
                this.specialPass =true;
            }

            if((this.lengthCheck || this.NumCheck || this.lowerPass || this.upperPass || this.specialPass)){
                this.regChecks = true;
            }else{
                this.regChecks = false;
            }
           
        },
        passCheck : function(e){
            let mainPass = document.querySelector('input[name="password"]') as HTMLInputElement;
            if(e.target.value != mainPass.value ){
                // console.log(this.$refs.password,"sasdsads")
                this.matchFields=true;
                this.forConfirm=true;
            }else{
                this.matchFields=false;
                this.forConfirm=false;
            }
        },
        passCheck1 : function(e){
            let confirmPass = document.querySelector('input[name="Cpassword"]') as HTMLInputElement;
            console.log(e.target.value, confirmPass.value, "from pass check 1")
            if(e.target.value != confirmPass.value ){
                // console.log(this.$refs.password,"sasdsads")
                this.matchFields=true;
                this.forConfirm=true;
            }else{
                this.matchFields=false;
                this.forConfirm=false;
            }
        }

    },
})

<template>
  <transition name="fade">
    <img
      :src="profileImage"
      :key="profileImage"
      :alt="imageAlt"
      :class="classes"
      :style="{ height: imageHeight, 'max-height': maxHeight }"
    />
  </transition>
</template>
<script>
import { computed, defineComponent, reactive, ref, watch } from "vue";
import { proxyApi } from "@/http/http.config";
import { encodeWithParam } from "@/util/DataEncodeDecode";
import {convertBufferToContent} from "@/util/BufferContentConverter";

export default defineComponent({
  name: "FileViewer",
  props: {
    imageId: {
      type: String,
      required: false,
    },
    classes: { type: String, required: false },
    height: {
      type: String,
      required: false,
      default: "100%",
    },
    maxHeight: {
      type: String,
      required: false,
      default: "100%",
    },
    isDownloadInsuranceImg: {
      type: Boolean,
      required: false
    },
  },
  setup(props) {
    const profileImage = ref("");
      if(props.imageId){
        proxyApi.post('/getUploadedFile', encodeWithParam({ fileID : props.imageId}))
        .then(({data}) => {
          profileImage.value =  data.bufferInHex ? convertBufferToContent(data.bufferInHex,data.contentType) : null
          })

      }
    const fileDetail = reactive({
      id: "",
      name: "",
      path: "",
      public: false,
    });

    const imageHeight = computed(() => props.height);
    const imageAlt = computed(() => {
      if (fileDetail && fileDetail.name) {
        return fileDetail.name;
      } else {
        return "Image";
      }
    });

    const downloadImage = (src, name) => {
      const link = document.createElement('a');
      link.href = src;
      link.download = name ?? 'image.jpg'; 
      link.click(); 
    }

    watch(() => props.isDownloadInsuranceImg, () => {
      if(profileImage.value) {
        downloadImage(profileImage.value, 'insurance.png');
      } 
    })

    return { fileDetail, imageHeight, imageAlt, profileImage };
  },
});
</script>

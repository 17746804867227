
import { defineComponent, ref, computed, onMounted, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
export default defineComponent({
  name: "retryPII",
  props: ['driversData'],
  components: {
    
  },
  setup(props) {
    var countLeft = ref(true);
    
    const router = useRouter();
    let driversDetailsToSend = [];
    if(typeof props.driversData != 'undefined'){
      let driversDetails = JSON.parse(props.driversData); 
      // console.log(driversDetails, "check count")
       countLeft.value = driversDetails.count;
       driversDetailsToSend = driversDetails;
    }
    

    const redirectTONext = async () => {
      // console.log("dfdfdfdf")
    return router.push({
                name: "ca-personalInfo",
                params: {
                      driversDetails: JSON.stringify(driversDetailsToSend)
                    }
              });
  };

  return {
    redirectTONext,
    countLeft
  }
  },
 methods: {
  
 }
});

import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { authApi } from "@/http/http.config";
import router from "@/router";
import { state } from "@/harlem/stores";

export default (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const isAuthenticated = !!localStorage.getItem("access_token");
  const isPaymentMethodPending = (state.payment.branchResponse == "REVIEW" || state.user.oneStatus == "PENDING")
// console.log(from.name,isAuthenticated,to.name)

// for Canada

 

  if (from.name == "ca-dashboard" && isAuthenticated && to.name == "ca-login") {
    return router.push({ name: "ca-dashboard" })
  }

  if (from.name == "checklist" && isAuthenticated && to.name == "ca-dashboard") {
    return router.push({ name: "checklist" })
  }

  if (from.name == "kycPending" && isAuthenticated && to.name == "ca-login") {
    return router.push({ name: "kycPending" })
  }

  if (from.name == "accountProcessing" && isAuthenticated && to.name == "PersonalDirectDeposit") {
    return router.push({ name: "accountProcessing" })
  }
  if (from.name == "kycProcessing" && isAuthenticated && to.name == "fullProfileDetails") {
    return router.push({ name: "kycProcessing" })
  }
  if (from.name == "kycProcessing" && isAuthenticated && to.name == "personalInfoConfirmation") {
    return router.push({ name: "kycProcessing" })
  }

  if (from.name == "ca-login" && !isAuthenticated && (to.name == "ca-dashboard" || to.name == "checklist" || to.name == "enrollment-checklist")) {
    return router.push({ name: "ca-login" })
  }

  if (from.name == "CreatePassword" && !isAuthenticated && to.name == "personalInfoConfirmation") {
    return router.push({ name: "CreatePassword" })
  }

  if (from.name == "checklist" && isAuthenticated && (to.name =='ca-login' || to.path == '/' || to.name == 'home' )) {
    return router.push({ name: "checklist" })
  }

  // if (from.name == undefined && isAuthenticated && to.name == "ca-login") {
  //   return router.push({ name: "ca-login" })
  // }
  // console.log(document.referrer, "from url")

  if (from.name == undefined && !isAuthenticated && (to.name == "ca-dashboard" || to.name == "PersonalDirectDeposit" || to.name == "ca-deposite-summary-home" || to.name == "ca-deposite-summary" || to.name == "ca-earnings-account-home" || to.name == "ca-earnings-account" || to.name == "fullProfileDetails" || to.name == "UploadCardDetails" || to.name == "PersonalDocuments-ca" || to.name == "PersonalDocumentsEdit" || to.name == "checklist")) {
    return router.push({ name: "ca-login" })
  }

 
// till here for canada
  



  if (from.name && (from.name as string).toLowerCase() === "/signup/signature" && to.name && (to.name as string).toLowerCase() == "/signup/backgroundCheck") {
    return router.push({ name: "/signup/signature" })
  }

  if (
    ((from.path && (from.path as string).toLowerCase() === "/signup/checklist/insuranceupload") || 
    (from.path && (from.path as string).toLowerCase() === "/signup/insuranceupload")) &&
    (to.name && (to.name as string).toLowerCase() == "/signup/documents")
  ) {
    return router.push({name: from.path})
  }

  if (
    ((from.path && (from.path as string).toLowerCase() === "/signup/checklist/earningsaccount") || 
    (from.path && (from.path as string).toLowerCase() === "/signup/earningsaccount")) &&
    (to.name && (to.name as string).toLowerCase() == "/signup/insuranceUpload")
  ) {
    return router.push({name: from.path})
  }

  if ((to.name == "earnings" || to.name == "deposit-summaries" || to.name == "manage-earnings") && isPaymentMethodPending) {
     next({name: "dashboard"})
     return
  }

  if (to.name && (to.name as string).toLowerCase() === "login") {
     const hasAppealsQuery = (to.query.service == "appeals")
    if (isAuthenticated && !hasAppealsQuery) {
      next("/");
    } else {
      next();
    }
  } else {
    if (!isAuthenticated && from.name && (from.name as string).toLowerCase() === "login" && to.name && (to.name as string).toLowerCase() === "signup") {
      router.push({ name: "login" });
    }

    if (to.meta.open) {
      next();
    } else {
      if (isAuthenticated) {
        if (to.meta.layout == 'signup' && to.name === 'completed') {
          return router.push({ name: "dashboard" })
        }
        next()
      } else {
        if (!isAuthenticated && from.name === "OneLandingPage" && to.name === "OneLogin") {
          next();
        }
        else {
          next("/login");
        }

      }
    }
  }
  return true;
};

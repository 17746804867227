

import { defineComponent } from "vue";
import IconArrowRight from "@/components/icons/IconArrowRight.vue";

export default defineComponent({
  name: "faqItem",
  components: { IconArrowRight},
  emits: ["toggle"],
  props: {
    id:{
      type: Number,
      required:true,
      default: 0
    },
    data:{
      type: Object,
      required: true,
      default: () => {
        return {
          id:Number,
          question:String,
          answer:String
        }
      }
    }
  },
  setup(props, { emit }) {

    function toggleExpansion(){
      emit("toggle",props.data.id)
    }

    return { toggleExpansion }
  },
})


import { computed, defineComponent, onMounted, ref } from "vue";
import { Field, Form } from "vee-validate";
import { FieldError, IconEyeClosed, IconEyeOpen } from "../../components";
import * as yup from "yup";
import { useRoute, useRouter } from "vue-router";
import {  signup } from "@/http/http.config";
import { state } from "@/harlem/stores";
import Base64 from "base-64"
import { encodeWithParam } from "@/util/DataEncodeDecode";
import { gTagEvent } from "@/util/GoogleAnalytics";
//query param is token need userID- actual stores id, token and password.

export default defineComponent({
  name: "ChangePassword",
  components: { Form, Field, FieldError, IconEyeClosed, IconEyeOpen },
  data(){
        return{
            forConfirm:true,
            regChecks:true,
        }
    },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const passwordHidden = ref(true);
    const confirmPasswordHidden = ref(true);
    const togglePassword = () => (passwordHidden.value = !passwordHidden.value);
    const toggleConfirmPassword = () => (confirmPasswordHidden.value = !confirmPasswordHidden.value);
    const lengthCheck = ref(true);
    const NumCheck = ref(true);
    const upperPass = ref(true);
    const lowerPass = ref(true);
    const specialPass = ref(true);
    const matchFields = ref(true);
    const viewPassword = computed(() => {
      return passwordHidden.value ? "password" : "text";
    });
    const viewConfirmPassword = computed(() => {
      return confirmPasswordHidden.value ? "password" : "text";
    });

    onMounted(() => {
      if(!sessionStorage.getItem("username")){
        router.push({ name: "ca-login" });
      }
    });

    const setNewPassword = (formData: Record<string, any>) => {
      const passwordReset = {
        password: formData.password,
      };

  signup.post("/changeForgottenPasswordCA",encodeWithParam(passwordReset),{headers:{"Authorization":route.query.resource}}).then((data) => {
        const res = data.data
        if (res.success) {
          gTagEvent("Success Password Change", "Login", {username: sessionStorage.getItem("username")})
          sessionStorage.removeItem("username")
          router.push({ name: "passwordSuccess" });
        }
      });
    };
    const schema = yup.object().shape({
      password: yup
      .string()
      .required()
      .test(
        "Password",
        "Password must contain uppercase, lowercase, numbers and special characters.",
        function (value: any) {
          const regex = new RegExp(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*()\-\\+={}_|/:;"'<>,.?])[A-Za-z\d~`!@#$%^&*()\-\\+={}_|/:;"'<>,.? ]{8,}$/
          );
          return regex.test(value);
        }
      )
      .min(8).label("Password"),
      confirmPassword: yup
        .string()
        .required("Confirm Password is a required field")
        .oneOf([yup.ref("password"), null], "Passwords must match"),
    });
    return {
      setNewPassword,
      togglePassword,
      viewPassword,
      passwordHidden,
      schema,
      toggleConfirmPassword,
      confirmPasswordHidden,
      viewConfirmPassword,
      lengthCheck,
      NumCheck,
      upperPass,
      lowerPass,
      specialPass,
      matchFields
    };
  },
  methods:{
    passRequirement :function(e){
      const NumRegex = /\d/;
      const lowerRegex = /[a-z]/;
      const upperRegex = /[A-Z]/;
      const specialRegex = /[^0-9a-zA-Z\s'-]/
      if(e.target.value.length > 7 ){
          this.lengthCheck = false;
      }else{
          this.lengthCheck = true;
      }

      if(NumRegex.test(e.target.value)){
          this.NumCheck = false;
      }else{
          this.NumCheck = true;
      }
      if(upperRegex.test(e.target.value)){
          this.upperPass = false;
      }else{
          this.upperPass = true;
      }
      if(lowerRegex.test(e.target.value)){
          this.lowerPass = false;
      }else{
          this.lowerPass = true;
      }
      if(specialRegex.test(e.target.value)){
          this.specialPass = false;
      }else{
          this.specialPass =true;
      }

      if((this.lengthCheck || this.NumCheck || this.lowerPass || this.upperPass || this.specialPass)){
          this.regChecks = true;
      }else{
          this.regChecks = false;
      }

    },
    passCheck : function(e){
            let mainPass = document.querySelector('input[name="password"]') as HTMLInputElement;
            if(e.target.value != mainPass.value ){
                // console.log(this.$refs.password,"sasdsads")
                this.matchFields=true;
                this.forConfirm=true;
            }else{
                this.matchFields=false;
                this.forConfirm=false;
            }
        },
    passCheck1 : function(e){
      let confirmPass = document.querySelector('input[name="confirmPassword"]') as HTMLInputElement;
      if(e.target.value != confirmPass.value ){
          this.matchFields=true;
          this.forConfirm=true;
      }else{
          this.matchFields=false;
          this.forConfirm=false;
      }
    }
  }
});


import { defineComponent } from "vue";
export default defineComponent({
  name: "IconCrossAlert",
  props: {
    checked: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
});

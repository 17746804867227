import { markRaw } from "vue";
import { state } from "@/harlem/stores";
import { IconOne, IconBank, IconBranch } from "@/components";

const partnerInfo = {
    locationName: state.user.locationName
}

export function getEnabledPayments(isAchEnabled, isOneEnabled) {
    const payments = ['branch']
    if (isAchEnabled) {
        payments.push('ach')
    }
    if (isOneEnabled) {
        payments.push('one')
    }
    return payments
}

export const cardsData = [
    {
        id: 1,
        type: 'one',
        logo: markRaw(IconOne),
        header: 'Earnings after every trip with ONE',
        bulletPoints: [
            'Get trip earnings after every trip, for free — plus, no monthly fees or minimum balances', 
            `Earn 5.00% Annual Percentage Yield(APY) on your Savings, with eligible deposits*`,
            `Earn 3% cash back at Walmart, up to $50 a year, with eligible deposits*`, 
            
        ],
        buttonText: "APPLY FOR ONE",
        buttonRoute: "route",
        faqsText: "Learn more about ONE",
        faqsLink: `/one/faqs?name=${partnerInfo.locationName}`
    },
    {
        id: 2,
        type: 'ach',
        logo: markRaw(IconBank),
        header: 'Weekly earnings with direct deposit',
        bulletPoints: [
            'Use your existing bank account to receive weekly electronic deposits', 
            'Enter your bank account details and we’ll link it to your Spark Driver™ account'
        ],
        buttonText: "LINK BANK ACCOUNT",
        buttonRoute: "route",
        faqsText: "Learn more about direct deposit",
        faqsLink: `/faq/directDeposit?name=${partnerInfo.locationName}`
    },
    {
        id: 3,
        type: 'branch',
        logo: markRaw(IconBranch),
        header: 'Weekly earnings in a Branch Wallet',
        bulletPoints: [
            'Receive trip earnings weekly in your Branch Wallet', 
            'Access over 55,000 fee-free Allpoint ATMs ', 
            'No overdraft fees, late fees, or minimum balance requirements'
        ],
        buttonText: "APPLY FOR BRANCH",
        buttonRoute: "route",
        faqsText: "Learn more about Branch",
        faqsLink: `/faq/branch?name=${partnerInfo.locationName}`
    }
]
import { state } from "@/harlem/stores"
import { proxyApi } from "@/http/http.config"
import { encodeWithParam } from "@/util/DataEncodeDecode"
import { multiplePayDisabled } from "../earnings/Helper";


export const getOneStatus = async () => {
  let oneStatus;
  await proxyApi.get('/oneStatus').then(({ data }) => {
    const status = data.result[0]?.one_status
    oneStatus = status
  })
  return oneStatus;
}

export const getBranchStatus = async () => {
  // let branchStatus = null
  // await proxyApi.get('/getWalletStatus').then(({ data }) => {
  //   if (data.result?.length > 0) {
  //     const status = data.result[0]?.status
  //     branchStatus = status
  //   }
  // })
  // return branchStatus;
}

export const getActiveEarningAccounts = async () => {
  let result: any = true
  result = await proxyApi.get('/getActiveEarningAccounts').then(({ data }) => {
    const message = data?.message
    if (message == "No active accounts") {
      return true
    }

    return false
  })
  return result
}

export const getRawBranchCount = (candidate) => {
  return proxyApi.get('/getBranchRawCount').then((data) => {
    const rowCount = data.data.result[0]?.count
    if (rowCount > 0) {
      candidate.branchRetryCount = rowCount
    }
  })
}

export const getPartnerInsuranceDetails = (candidate) => {
  return proxyApi.get('/getPartnerInsuranceDetails').then(async (data) => {
    const rowCount = data.data.rowCount
    if (rowCount > 0) {
      const insuranceExpiry = data.data?.result[0].insuranceExpiry
      candidate.insuranceExpiry = insuranceExpiry
    }
  })
}

export const getProgressStatus = (candidate, funnelProgress, funnelProgressEmpty) => {
  return proxyApi.post(
    '/getFunnelProgress',
    encodeWithParam({ candidateID: state.user.locationPartnerId }))
    .then(({ data }) => {
      const candidateFunnel = data.result

      if (candidateFunnel?.length == 0) {
        return funnelProgressEmpty.value = true
      }

      const currentStatus = candidateFunnel[0]
      candidate.progress.status = currentStatus.candidateStatus
      candidate.progress.statusDetail = currentStatus.candidateStatusDetail
      candidate.progress.description = currentStatus.description
      candidate.progress.name = currentStatus.name
      candidate.progress.stage = currentStatus.stage

      funnelProgress.value = candidateFunnel
    })
}

export const getDLExpiry = (candidate) => {
  return proxyApi.get('/getPersonalDetails').then(({ data }) => {
    const isDLEntered = data?.result[0]?.number != null
    if (isDLEntered) {
      candidate.dlExpiry = data.result[0].expiration
    }
  })
}

export const isLocationRecruiting = (candidate, router) => {
  proxyApi.post("/validateLocationStatus", encodeWithParam({ clientLocationID: state.user.locationId })).then((data: any) => {
    candidate.location.IsRecruiting = data.data?.result[0]?.isRecruiting
    if (candidate.location.IsRecruiting && (candidate.progress.status == 'Enrollment On-Hold')) {
      router.push({ name: 'personalDetails', query: { locationId: state.user.locationId } })
    }
  })
}

export const setOneStatusRef = async () => {
  const status = await getOneStatus();
  return status;
};

export const getHiddenEarningAccounts = async () => {
  let attemptedEarningAccount = false;
  await proxyApi.get('/getHiddenEarningAccounts').then(({data}) => {
    const hiddenAccounts = data?.hiddenAccounts
    if (hiddenAccounts?.length > 0) {
      attemptedEarningAccount = true;
    }
  })
  return attemptedEarningAccount;
}

export const getONETransactionsCount = (candidate) => {
  proxyApi.post("/getOneEarningForPayPeriod", encodeWithParam({ startDate: "2010-01-01", endDate: getTodaysDate() }))
    .then(({ data }) => {
      const { result } = data
      candidate.isEarningThroughONE = result?.length > 0
    })
};

export const getKycStatus = (kycStatus) => {
  return proxyApi.get("/getKycStatus")
    .then(({ data }) => {
      const kyc_status = data?.result.kyc_status;
      kycStatus.value = kyc_status;
    })
}

const getTodaysDate = () => {
  const now = new Date(Date.now());
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export const getTransactionsCount = (candidate) => {
  proxyApi
    .post("/getEarnings", encodeWithParam({ endDate: "2010-01-01" }))
    .then(({ data }) => {
      const { result } = data
      candidate.isEarning = result?.length > 0
    })
}

export const getWalletInfo = async (candidate, showWalletLink) => {
  // await proxyApi.get('/getWalletStatus').then(({ data }) => {
  //   if (data.result?.length > 0) {
  //     const branchStatus = data?.result[0]?.status
  //     const claimed = ["ACTIVE"]
  //     candidate.branchStatus = branchStatus
  //     if (!claimed.includes(data?.result[0].status) && data.result[0].magicLink) {
  //       showWalletLink.value.show = true;
  //       showWalletLink.value.url = data?.result[0].magicLink
  //     }
  //   }
  // })
}

export const checkACHReachedMaxAttempts = async () => {
  let hasReachedMaxAttempts = false;
  await proxyApi.get('/getAchOnboardingDetails').then(({ data }) => {
    const attempts = data?.attempts
    const maxAttempts = data?.maxAttempts

    if (attempts >= maxAttempts) {
      hasReachedMaxAttempts = true
    }
  })
  return hasReachedMaxAttempts
}

export const isAllPaymentMethodsFailed = async () => {
  const oneStatus = await getOneStatus()
  const branchStatus: any = await getBranchStatus()
  const hasACHReachedMaxAttempts = await checkACHReachedMaxAttempts()

  return oneStatus == 'DENIED' && branchStatus == "FAILED" && (hasACHReachedMaxAttempts)
}

export const defaultLocationPaymentsFailed = async () => {
  const oneStatus = await getOneStatus()
  const branchStatus: any = await getBranchStatus()
  const isMultiplePayDisabled = await multiplePayDisabled();

  return oneStatus == 'DENIED' && branchStatus == "FAILED" && isMultiplePayDisabled
}

import { DateTime } from 'luxon'
import MicroModal from 'micromodal';
import { useRouter } from 'vue-router';
import { proxyApi } from '@/http/http.config';
import Loader from "@/components/modals/Loader.vue";
import { defineComponent, onMounted, ref, onBeforeMount } from 'vue';
import Snackbar from '@/components/snackbar/Snackbar.vue';
import { encodeWithParam } from '@/util/DataEncodeDecode';
import PrimaryText from '@/modules/earnings/activeAccounts/PrimaryText.vue'
import { activeAccountsData } from '@/modules/earnings/activeAccounts/ActiveAccountsData'
import { IconArrowRight, IconWarningTriangle, IconInfoWalmart, NoActivePayments, ConfirmMakePrimaryEarning } from "@/components";
import { disableEarningsTab } from "@/router/Helpers";
import { gTagEvent } from '@/util/GoogleAnalytics';
import { state } from '@/harlem/stores';

export default defineComponent({
  name: "ActiveAccount",
  components: {
    Loader,
    Snackbar,
    PrimaryText,
    IconArrowRight,
    IconInfoWalmart,
    NoActivePayments,
    IconWarningTriangle,
    ConfirmMakePrimaryEarning
  },
  setup() {
    const router = useRouter()
    const showSnackbar = ref(false)
    const selectedPaymentProvider = ref("")
    const achDetails = ref({})
    const activeAccounts = ref(activeAccountsData)
    const noActiveEarningAccounts = ref(false)
    const payStartDate:any = ref(null)
    const isLoading = ref(true);
    const sessionData: any = sessionStorage.getItem("ddi:user");
    const { garnishment} = JSON.parse(sessionData);
    onMounted(async () => {
      showAccountChanged()
      proxyApi.get('/getActiveEarningAccounts').then(async (data) => {
        if (data?.data?.message) {
          noActiveEarningAccounts.value = true
        }
        let primaryIndex = 0
        if (router.currentRoute.value.path === '/earnings') {          
          activeAccounts.value = activeAccounts.value.filter(item => { 
              return item.type === data.data?.paymentProvider?.primary; 
          });
        
        } else {
          activeAccounts.value = activeAccounts.value.filter(item => {
            return data.data.activeAccounts?.includes(item.type)
          });        
        }
        activeAccounts.value.forEach((item, index) => {
          if (item.type === 'ach') {
            activeAccounts.value[index].accountNumber = data.data.achDetails.lastFourAccountNumber
            activeAccounts.value[index].isSavings = data.data.achDetails.isSavings
            if (data.data.achDetails.bankName) {
              activeAccounts.value[index].title = data.data.achDetails.bankName
            }
          }
          if (item.type === data.data?.paymentProvider?.primary) {
            activeAccounts.value[index].isPrimary = true
            primaryIndex = index
          } else {
            activeAccounts.value[index].isPrimary = false
          }
          if (item.type === data.data?.paymentProvider?.pending) {
            activeAccounts.value[index].isPending = true
            payStartDate.value = DateTime.fromISO(data.data.paymentProvider.payStartDate).toFormat('MM/dd/yy')
          }
        })

        isLoading.value = false
        if (primaryIndex > 0) {
          let temp = activeAccounts.value[0]
          activeAccounts.value[0] = activeAccounts.value[primaryIndex]
          activeAccounts.value[primaryIndex] = temp
        }
      })
    })

    onBeforeMount(async () => {
      await disableEarningsTab().then((data) => {
        const isDisabled = data;
        if (isDisabled) {
          router.push({ name: "dashboard" })
        }
      })
      
    })

    const openModal = (paymentProvider) => {
      selectedPaymentProvider.value = paymentProvider
      MicroModal.show('confirm-add-earning-method')
    }

    const closeModal = () => {
      MicroModal.close('confirm-add-earning-method')
    }

    const switchPaymentProvider = (paymentProvider) => {
      const switchData = encodeWithParam({
        paymentProvider: paymentProvider
      })
      proxyApi.post('/switchPaymentProvider', switchData).then(({ data }) => {
        gTagEvent(`Switch Primary Payment Provider to ${paymentProvider}`, "Active Accounts", `partner_id: ${state.user.partnerId}`)
        sessionStorage.setItem('switchPaymentProviderSuccess', 'true')
        router.push({ path: 'earnings' })
      })
    }

    const showAccountChanged = () => {
      if (sessionStorage.getItem('switchPaymentProviderSuccess') === 'true') {
        showSnackbar.value = true;
        setTimeout(() => {
          showSnackbar.value = false
        }, 3000)
        sessionStorage.removeItem('switchPaymentProviderSuccess')
      }
    }

    const confirmMakePrimarySwitch = () => {
      switchPaymentProvider(selectedPaymentProvider.value)
      MicroModal.close('confirm-add-earning-method')
    }

    return {
      isLoading,
      activeAccounts,
      achDetails,
      router,
      payStartDate,
      openModal,
      closeModal,
      confirmMakePrimarySwitch,
      showSnackbar,
      selectedPaymentProvider,
      noActiveEarningAccounts,
      garnishment
    };
  },
});

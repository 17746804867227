
  
  import { defineComponent, ref } from "vue";
  import IconBlueClose from "../icons/IconBlueClose.vue";
  import IconBlueSupport from "../icons/IconBlueSupport.vue";
  import SelectorValidated from "../forms/SelectorValidated.vue";
  import router from "@/router";
  import { useRoute } from "vue-router";
  import { proxyApi } from "@/http/http.config";
  

  
  export default defineComponent({
    name: "ContactSupportModal",
    components: { IconBlueClose, IconBlueSupport, SelectorValidated},
    emits: ["close","redirect"],
    setup(props, { emit }) {
        const currentRoute = useRoute();
        const description:any = "";
        const category:any = null;
        const uploadedFiles:any = [];
        const apiResp = "";
        const fileErrors = ref("");
        const apiRespStatus = ref(false);
        const categoryError = ref(false);
        const descriptionError = ref(false);
        const disableSubmit = ref(false);
        return { currentRoute, description, category, categoryError, descriptionError, disableSubmit, apiResp, uploadedFiles, apiRespStatus, fileErrors }
    },
    methods: {
        close(e:any, value = false){
            this.$emit("close",{e:e, value:value});
        },
        redirectToFaq(){
            if(this.currentRoute.path == "/ca-faq"){
                this.close("",false);
                return;
            }
            this.close("",false);
            router.push({
                name : "ca-faq"
            })
        },
        redirectToDashboard(){
          this.close("", true);
          // router.push({
          //       name : "ca-dashboard"
          //   })
        },
        handleCategorySelect(item:any){
            this.category = item;
            if(item) this.categoryError = false;
        },
        onChangeDescription(e:any){
            this.description = e.target?.value
            if(e.target?.value) this.descriptionError = false;
        },
        fileSelected(event: any) {
            const input: HTMLInputElement = event.target;
            if (input.files === null || input.files.length == 0) {
              this.uploadedFiles = []; 
              return;
            }
            let acceptedFormat = ["jpg", "png", "jpeg"];
            let fileFormats:string[] = [];

            let fileSize = 0 ;
            let cnt = 0;
            for(const file of input.files){
              fileFormats.push(file.name.split(".")[1].toLowerCase())
              fileSize += file?.size || 0;
              cnt ++;
            }
            let isThereInvalidFormats = fileFormats.every((format:string)=>{
              return acceptedFormat.includes(format);
            })
            if(!isThereInvalidFormats){
                this.fileErrors = `Allowed file formats ${acceptedFormat.join(", ")}`;
                input.value = "";
                return;
            }
            if (fileSize && fileSize >= 5242880) {
              this.fileErrors = `File size should be less than 5mb`;
              input.value = "";
              return;
            }
            if (cnt && cnt > 5) {
                this.fileErrors = `Number of attachments limited to 5`
                input.value = "";
                return;
            }
            this.fileErrors = "";
            this.uploadedFiles = input.files;
        },
        clearErrors(){
          this.categoryError = false;
          this.descriptionError = false;
        },
        isThereErrors(){
          return this.categoryError || this.descriptionError;
        },
        checkDmsId():any{
          return sessionStorage.getItem('payeeID') !== 'null' ? true : false;
        },
        categoryOptions(){
          return this.checkDmsId()?[
            { value: 'Onboarding', text: 'Profile updating issue' },
            { value: 'Payment', text: 'Payment related inquiry' },
          ]:[
            { value: 'Onboarding', text: 'Onboarding application process' },
            { value: 'Payment', text: 'Payment setup process' },
          ];
        },
        async submitQuery(){
          this.apiResp = "";
          (!this.category)? (this.categoryError = true) : (this.categoryError = false);
         
          (!this.description)? (this.descriptionError = true) : (this.descriptionError = false);
          if(this.isThereErrors()) return;
          this.clearErrors();
          this.disableSubmit = true;
         
          const formData = new FormData();
          formData.append("isDriver", this.checkDmsId());
          formData.append("queryType", this.category);
          formData.append("des", this.description);
          for (const file of this.uploadedFiles){
            formData.append("file", file as Blob);
          }
          try {
            const resp = await proxyApi.post("/contactSupport", formData);
            this.disableSubmit = false;
            if(resp.data.success){
              this.apiRespStatus = true;
              this.redirectToDashboard();
            }else{
              this.apiRespStatus = false;
              this.apiResp = "Something went wrong, Please try again";
            }
          } catch (err) {
            this.disableSubmit = false;
          }
        }
        
    }
  })
  
  
import DashboardRoutes from "@/modules/dashboard/routes";
import LoginRoutes from "@/modules/login/routes";
import SignupRoutes from "@/modules/ca/routes";
import authGuard from "@/router/authGuard";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
// import config from "@/config";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/error",
    name: "errorPage",
    component: () => import("@/components/ErrorPage.vue"),
    meta: { title: "Delivery Drivers Inc", noHeader: true, open: true },
  },
  {
    path: "/ca-login",
    name: "ca-login",
    component: () => import("@/modules/ca/login.vue"),
    meta: { title: "Delivery Drivers Inc | Login", open: true, layout: "account" },
  },
  {
    path: "/",
    name: "home",
    component: () => import("@/modules/ca/login.vue"),
    meta: { title: "Delivery Drivers Inc | Login", noHeader: true, open: true, layout: "account" },
  },
  {
    path : "/ForgotPasswordCA",
    name: "ForgotPasswordCA",
    component: () => import("@/modules/ca/ForgotPaswordCA.vue"),
    meta:  { title: "Delivery Drivers Inc | Login", open: true, layout: "account" },
  },
  {
    path: "/ca-faq",
    name: "ca-faq",
    component: () => import("@/modules/ca/faq/faq.vue"),
    meta: { title: "Delivery Drivers Inc | FAQ", noHeader: false, open: true, layout: "account" },
  },
  {
    path: "/ca-settings",
    name: "ca-settings",
    component: () => import("@/modules/ca/settings/settings.vue"),
    meta: { title: "Delivery Drivers Inc | FAQ", noHeader: false, open: true, layout: "account" },
  },

        
  ...LoginRoutes,
   ...SignupRoutes,
  ...DashboardRoutes,

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  },
  routes,
});

router.beforeEach(authGuard);
router.beforeEach((to, from, next) => {
  // const isAuth = !!localStorage.getItem("access_token")
  // console.log(isAuth,to.params.locationId)

// if(!isAuth){
//   if(to.name == "LandingPage" && to.params.locationId != 'undefined'){
//     next();
//   }
//   if(to.name != 'ca-login' && to.params.locationId == 'undefined'){
//     router.push('ca-login')
//   }
// }else{
//   if(to.name =='ca-login' || to.path == '/' || to.name == 'home'){
//     next(false);
//     router.push(from.path)
//   }else{
//     next();
//   }
// }

  // if(!isAuth && to.name == "LandingPage"){
  //   next();
  // }
  // // else if(to.name =='ca-login' && isAuth){
  // //   next(false);
  // //   router.push(from.path)
  // // }
  // else if(!isAuth && to.name != 'ca-login' ){
  //   router.push('ca-login')
  // }
  // else{
  //   next();
  // }
  // const excludePages=['/one/signup','/one/login','/one/faqs']

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  document.title = to.meta.title || "Walmart";

  next();
});

export default router;

export enum Constants {
  ACCESS_TOKEN_KEY = "access_token",
  EXPIRY = "expiration",
  TOKEN_URL = "/auth/token",
  REFRESH_COOKIE = "_ddiRefresh",
  LOGOUT_URL = "/auth/logout",
}

export const usernameEmail = `
<p>The Username for the Delivery Drivers Inc. account associated with this email address was requested. Below are the details requested:</p>
<p style="font-weight:700">{{username}}</p>
<p>If you did not make a request, you can ignore this email</p>
<div style="text-align: center; margin-top: 40px;"><button style="color: white; background-color: #16789E; border-radius: 4px; width: 20%; height:40px">
<a href={{link}} style="color: white; text-decoration:none"> Login to DDI</a> </button></div>
`;


import { defineComponent, reactive, Ref, ref, onMounted } from "vue";
import { Form } from "vee-validate";
import { DateTime } from "luxon";
import * as yup from "yup";
import { DriversLicenseValidation } from "@/util/DriverLicenseValidation";
import DateDropDown from "@/components/forms/DateDropDown.vue";
import StateSelectorValidated from "@/components/forms/StateSelectorValidated.vue";
import TextFieldValidated from "@/components/forms/TextFieldValidated.vue";
import { state } from "@/harlem/stores";
import { proxyApi } from "@/http/http.config";
 import { encodeWithParam } from "@/util/DataEncodeDecode";
 import router from "@/router";
 import { IconWarningOctagon } from "@/components";
import documentMethods from "../../documents/Helpers/Index";
import { gTagEvent } from "@/util/GoogleAnalytics";

export default defineComponent({
  name: "PersonalDocumentsEdit",
  components: {
    TextFieldValidated,
    StateSelectorValidated,
    DateDropDown,
    Form,
    IconWarningOctagon
  },
  setup() {
    const toastMessage = ref("");
    const insuranceFilePreview: Ref<File | null> = ref(null);
    const isExpiryError = ref(false)
    const dateValidationError = ref(false);
    const fifteenYears = ref(false);
    const dlValidationError = ref(false);
    const initialValues = reactive({
      license: {
        num: "",
        lastFourNum: "",
        state: "",
        exp: "",
        date: {
          year: "Year",
          month: "Month",
          day: "Day"
        },
      },
      sqlRowID: ""
    })

    onMounted(() => {
      proxyApi.get('/getPartnerDashboardDLDetails').then(({data})=>{
          if(data.success){
            const {number, state, expiration, dlLastFour, id } = data.result[0]
            initialValues.license.num = number
            initialValues.license.lastFourNum = dlLastFour
            initialValues.license.state = state
            initialValues.license.exp = expiration
            initialValues.sqlRowID = id
            documentMethods.splitExpDate(expiration, initialValues.license.date)
          }            
      });
      gTagEvent("View Drivers License Page", "Partner Dashboard", `partner_id: ${state.user.partnerId}`)
    })
    
    const handleEditDL = (detail, e) => {
      initialValues.license[detail] = e
    }
  
    const licenseForm = reactive({
      number: "",
      state: "",
      expiration: "",
    });
   
    const expirydateValidate = (data: any) => {
      let {0: year, 1: month, 2: day } = data.split("-");
      if(year.toLowerCase() == 'year') {
        year = initialValues.license.date.year;
      }
      if(month.toLowerCase() == 'month') {
        month = initialValues.license.date.month;
      }
      if(day.toLowerCase() == 'day') {
        day = initialValues.license.date.day;
      }
      data = year + '-' + month + '-' + day;
      dateValidationError.value = false;
      isExpiryError.value = false;
      fifteenYears.value=false;
      let futuredate = isFutureDate(data);
      if (futuredate) {
       initialValues.license.exp = data;
      } else {
        isExpiryError.value = true;
      }
      let futDate= fDate(data);
      if (futDate){
        initialValues.license.exp =data;
      }else {
        fifteenYears.value =true
      }
    
    }
    const fDate = (dateVal)=>{
      const thisYear = new Date().getFullYear()
      const ending = new Date(dateVal).getFullYear();
      if ((ending-thisYear) < 15){
        return true;
      }else return false; 
    }

    // const isFutureDate =  (dateVal) => {
    //   var g1 = new Date();
    //   var g2 = new Date(dateVal);
    //   if (g1.getTime() < g2.getTime()) return true;
    //   else if (g1.getTime() > g2.getTime()) return false;
    // }

    const isFutureDate =  (dateVal) => {
      var g1 = new Date();
      g1.setDate(g1.getDate() + 45);
      var g3 = new Date(dateVal);
      if (g3.getTime() < g1.getTime()){
        return false; 
      }else{
        return true;
      }
    }

    function getMinDate() {
      const g1 = new Date();
      g1.setDate(g1.getDate() + 45);
      return g1;
}


    const handleSaveDL = async () => {
      const {num, state, exp} = initialValues.license
      const obj = {
        id: initialValues.sqlRowID,
        number: num,
        expiration: exp,
        state: state
      }
      await proxyApi.post("/validateDL",encodeWithParam({dl: num})).then((data)=>{
        if ( data.data.result.length > 0 )
          dlValidationError.value = true;
        else
          dlValidationError.value = false;
      });
      if(isExpiryError.value) {
        dateValidationError.value = true;
      } else if(dlValidationError.value != true && fifteenYears.value != true) {
        proxyApi.post("/updateDL",encodeWithParam(obj)).then(()=>{
          gTagEvent("Update Drivers License", "Partner Dashboard", `partner_id: ${obj.id}`)
          router.push({ name: "PersonalDocuments-ca" });
        });
      }
    };
    const minDate = DateTime.local().toJSDate();

    const schema = yup.object().shape({
      num: yup
      .string()
        .trim("License number can't contain white space at the start or end")
        .strict(true)
        .required("Driver's license number is required")
        .matches(/^(?!-)[A-Za-z0-9]+([A-Za-z0-9]+)*$/, "Invalid format for DL"),
      state: yup.string().trim().required().label("Drivers License State"),
      exp: yup
      .date()
        .required("License expiration date is required")
        .label("License Expiration")
        .min(getMinDate(), "License cannot be expired1")
        ,
    });
    
    return {
      state,
      schema,
      toastMessage,
      insuranceFilePreview,
      minDate,
      isExpiryError,
      handleSaveDL,
      expirydateValidate,
      licenseForm,
      dateValidationError,
      dlValidationError,
      initialValues,
      handleEditDL,
      fifteenYears
    };
  }
});

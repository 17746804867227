
import { encodeWithParam } from "@/util/DataEncodeDecode";
import { Form, Field } from "vee-validate";
// import { TextFieldValidated } from "@/components";
import * as yup from "yup";
import {
  defineComponent,
  ref,
  computed,
  reactive,
  onMounted
} from "vue";
import { proxyApi } from "@/http/http.config";
import Autocomplete from "vue3-autocomplete";

export default defineComponent( {
  name: 'GoogleAuto',
  components: { Autocomplete, Field, Form, 
    // TextFieldValidated 
  },
  props: {
    businessaddressData: {
      type: Object,
      required: false,
      default: () => {
        return {
          id: "",
          baddress1: "",
          baddress2: "",
          bstate: "",
          bcity: "",
          bcounty: "",
          bPostalCode: ""
        }
      }
    },
    addressData: {
      type: Object,
      required: true,
      default: () => {
        return {
          id: "",
          address1: "",
          address2: "",
          province: "",
          city: "",
          county: "",
          PostalCode: ""
        }
      }
    }, 
  },
  setup(props,{emit}) {
    let addressSuggestions: any = ref([]);
    const addressSuggestionComputed = computed(() => addressSuggestions.value);

    const autocompleteGoogle = (searchText: string) => {
      addressSelectedFromGoogle.value = false;
      let input = searchText; 
      let type = 'address';
      proxyApi.post('/placeAutocomplete', encodeWithParam({ inputval: input,  type: type }))
        .then((data) => {
          if(data.data.result.status == 'ZERO_RESULTS'){
            addressSelectedFromGoogle.value = false;
            add2button.value = false;
          }
            addressSuggestions.value = data.data.result.predictions.map(item => {
            return {
              name: `${item.description}`,
              item: `${item.place_id}`,
            };
          })
          })
 
    };
    let addressSelectedFromGoogle = ref(false);
    const personalDetails = reactive({}) as {
      [key: string]: string | boolean | Date | null | undefined;
    };
    const postalError =ref(false)
    const updatePostcode=(event)=>{
      personalDetails.PostalCode=event.target.value
      if(event.target.value.length != 6){
        postalError.value = true;
        addressSelectedFromGoogle.value= false;
      }else{
        postalError.value =false;
        addressSelectedFromGoogle.value= true;
      }
    }
    const cityManual = (event)=>{
      personalDetails.city =event.target.value
    }
    const enableEdit=ref(true);
    personalDetails.address1='';
    personalDetails.address2='';
    personalDetails.city='';
    personalDetails.state='';
    personalDetails.PostalCode='';
    personalDetails.addressID = "";
    const selectedAddress = (selectedAddress: any, setFieldValue: any) => {
      let placeID = selectedAddress.item;
      proxyApi.post('/getPlaceDetails', encodeWithParam({ place_id: placeID }))
        .then((data: any) => {
          let addressComponents = data.data.result.result.address_components;
          if (addressComponents.length > 0) {
            const province = addressComponents.find(component => component.types.includes('administrative_area_level_1'))?.long_name || '';
            const address1 = (addressComponents.find(component => component.types.includes('street_number'))?.long_name || '') + ' ' + (addressComponents.find(component => component.types.includes('route'))?.long_name || '');
            const city = addressComponents.find(component => component.types.includes('locality'))?.long_name || '';
            const PostalCode = addressComponents.find(component => component.types.includes('postal_code'))?.short_name || '';

            personalDetails.address1 = address1;
            personalDetails.city = city;
            personalDetails.province = province;
            personalDetails.PostalCode = PostalCode;
            // let formattedAddress = ` ${province} , ${address1} , ${city} , ${PostalCode}`;
            setFieldValue('province', province.normalize('NFD')
                   .replace(/[\u0300-\u036f]/g, ''));
            setFieldValue('address1',address1);
            setFieldValue('city',city.normalize('NFD')
                   .replace(/[\u0300-\u036f]/g, ''));
            setFieldValue('PostalCode',PostalCode);
            addressSelectedFromGoogle.value= true;
            if(PostalCode=='' || PostalCode.length <5){
              enableEdit.value = false;
              postalError.value = true;
             }else{
              enableEdit.value=true
             }
             if(personalDetails.province =='Québec'){
              personalDetails.province = 'Quebec'

             }

            // console.log(personalDetails.city, "2nd api call")
          }
          else {
            console.log("failllll")
          }
        })
    }
    const validateAddress = (data) => {
      if (data?.state == "CA")
        return data.address1 && data.city && data.county && data.zip && data.state
      return data.address1 && data.city && data.zip && data.state
    }
    const formData = ref({})
    onMounted(async () => {
      // console.log(props.businessaddressData,personalDetails.address1,"google auto page" )
      personalDetails.address1 = props.addressData.address1 || '';
      personalDetails.address2 = props.addressData.address2 || '';
      personalDetails.city = props.addressData.city || '';
      personalDetails.state = props.addressData.state || '';
      // partnerData.county = props.addressData?.county || '';
      personalDetails.PostalCode = props.addressData.PostalCode || '';
      personalDetails.addressID = props.addressData.id || '';
      // selectedState.value = props.addressData?.state || '';
      personalDetails.baddress1 = props.businessaddressData.address1 || '';
      personalDetails.baddress2 = props.businessaddressData.address2 || '';
      personalDetails.bstate = props.businessaddressData.province || '';
      personalDetails.bcity = props.businessaddressData.city || '';
      personalDetails.bPostalCode = props.businessaddressData.zipcode || '';
      personalDetails.province = props.addressData.state || '';
      // console.log(props.businessaddressData,personalDetails.address1,"google auto 2" )



      
    });
    const saveAdd1=(data)=>{
      // formData.value = data
      // console.log("save-add",personalDetails.PostalCode)
      if (personalDetails.city != '' && personalDetails.PostalCode != '') {
        let obj = {
          address1: personalDetails.address1,
          address2: personalDetails.address2,
          state: personalDetails.province,
          city: personalDetails.city,
          postalCode: personalDetails.PostalCode,
          country: "Canada",
          addressID: personalDetails.addressID,
          // county: partnerData.state == "CA" ? allTrim(partnerData.county) : null
        };

        // let obj = {
        //   address1: "local street",
        //   address2: personalDetails.address2,
        //   state: "calgary",
        //   city: "ontario",
        //   postalCode: "1A2D1A",
        //   country: "CA",
        //   addressID: sessionStorage.getItem("addID"),
        //   // county: partnerData.state == "CA" ? allTrim(partnerData.county) : null
        // }
        // if (obj.state == "CA" && obj.county == "") {
        //   isCountyNull = true;
        // }
          proxyApi.post("/updateAddress", encodeWithParam(obj)).then(() => {
            emit("save-Add");
            // console.log(obj,"  obj obj")
          })
        
      }
    }
    const cancel = () => {
      emit("cancel-edit1");
    }
    const saveAdd = (data)=>{
      formData.value = data
    }
    const add2button =ref(false);

    const enableSave =(e)=>{
      if(e.target.value.length > 0){
        addressSelectedFromGoogle.value=true
        add2button.value=true
      }

    }
    const schema = yup.object().shape({
      PostalCode: yup
        .string()
        .trim()
        .required("Postal code is required")
        .min(6, "Postal Code must be 6 digits")
        .matches(/^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i, "Postal code is invalid") 
        .label("{Zip} Code"),
     
    });

    return {
      autocompleteGoogle,
      addressSuggestions,
      addressSuggestionComputed,
      selectedAddress,
      personalDetails,
      saveAdd,
      validateAddress,
      cancel,
      saveAdd1,
      updatePostcode,
      cityManual,
      addressSelectedFromGoogle,
      schema,
      enableEdit,
      postalError,
      enableSave,
      add2button
    };

  },
  // methods: {
  //   /**
  //    * When the location found
  //    * @param {Object} addressData Data of the found location
  //    * @param {Object} placeResultData PlaceResult object
  //    * @param {String} id Input container ID
  //    */
  //   getAddressData: function (addressData, placeResultData, id) {
  //     this.address = addressData;
  //   },
  // },
})

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1c5cf0cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "faq-item" }
const _hoisted_2 = { class: "que" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconArrowRight = _resolveComponent("IconArrowRight")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "header",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleExpansion && _ctx.toggleExpansion(...args)))
    }, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.data.question), 1),
      _createElementVNode("div", {
        class: _normalizeClass(["icon", _ctx.id == _ctx.data.id && 'rotateArrow'])
      }, [
        _createVNode(_component_IconArrowRight)
      ], 2)
    ]),
    (_ctx.id == _ctx.data.id)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "ans",
          innerHTML: _ctx.data.answer
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true)
  ]))
}
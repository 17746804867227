
import { defineComponent, ref, computed, onMounted, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import authenticationService from "@/services/authentication.service";
export default defineComponent({
  name: "kycPending",
  components: {
    
  },
  setup(props) {
    var countLeft = ref(true);
    
    const router = useRouter();
  
    

    const redirectTONext = async () => {
      authenticationService.logout();
      localStorage.removeItem("username");
      router.push({ name: "ca-login" });
  };

  return {
    redirectTONext,
    countLeft
  }
  },
 methods: {
  
 }
});

import { mutation } from "@/harlem/stores/user.store";

export const setUserId = mutation<string, any>(
  "setUserId",
  (state, payload) => {
    state.userId = payload;
  }
);
export const setUsername = mutation<string, any>(
  "setUsername",
  (state, payload) => {
    state.username = payload;
  }
);
export const setFirstName = mutation<string, any>(
  "setFirstName",
  (state, payload) => {
    state.firstName = payload;
  }
);
export const setLastName = mutation<string, any>(
  "setLastName",
  (state, payload) => {
    state.lastName = payload;
  }
);
export const setPreferredName = mutation<string, any>(
  "setPreferredName",
  (state, payload) => {
    state.preferredName = payload;
  }
);
export const setPhone = mutation<string, any>("setPhone", (state, payload) => {
  state.phone = payload;
});
export const setEmail = mutation<string, any>("setEmail", (state, payload) => {
  state.email = payload;
});

export const setCommEmail = mutation<string, any>(
  "setCommEmail",
  (state, payload) => {
    state.comm_email = payload;
  }
);

export const setSignature = mutation<string, any>(
  "setSignature",
  (state, payload) => {
    state.signature = payload;
  }
);
export const setGarnishment = mutation<boolean, any>(
  "setGarnishment",
  (state, payload) => {
    state.garnishment = payload;
  }
);

export const setIsOneActive = mutation<boolean, any>(
  "setIsOneActive",
  (state, payload) => {
    state.isActive = payload;
  }
);

export const setOneStartDate = mutation<string, any>(
  "setOneStartDate",
  (state, payload) => {
    state.oneStartDate = payload;
  }
);

export const setIsOneIntegrated = mutation<boolean, any>(
  "setIsOneIntegrated",
  (state, payload) => {
    state.isOneIntegrated = payload;
  }
);

export const setOneStatus = mutation<string, any>(
  "setOneStatus",
  (state, payload) => {
    state.oneStatus = payload;
  }
);

export const setPartnerId = mutation<string, any>(
  "setPartnerId",
  (state, payload) => {
    state.partnerId = payload;
  }
);
export const setLocationPartnerId = mutation<string, any>(
  "setLocationPartnerId",
  (state, payload) => {
    state.locationPartnerId = payload;
  }
);
export const setClientId = mutation<string, any>(
  "setClientId",
  (state, payload) => {
    state.clientId = payload;
  }
);

export const setIP = mutation<string, any>("setIP", (state, payload) => {
  state.ip = payload;
});
export const setLocationId = mutation<string, any>(
  "setLocationId",
  (state, payload) => {
    state.locationId = payload;
  }
);

export const setLocationName = mutation<string, any>(
  "setLocationName",
  (state, payload) => {
    state.locationName = payload;
  }
);


import checklistMethods from "../components/Checklist/Helpers/Index";
import { defineComponent, ref, computed } from "vue";
import { IconArrowLeft } from "@/components";
import { Form, Field } from "vee-validate";
import * as yup from "yup";
import { TextFieldValidated, FieldError } from "@/components";
import Autocomplete from "vue3-autocomplete";
import { proxyApi } from "@/http/http.config";
import { encodeWithParam } from "@/util/DataEncodeDecode";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "CARegisterBusiness",
  components: {
    IconArrowLeft,
    Form,
    Field,
    TextFieldValidated,
    FieldError,
    Autocomplete,
  },
  setup() {
    let businessaddressSuggestions: any = ref([]);
    const autoComplete = ref<InstanceType<typeof Autocomplete>>();
    const enableEdit = ref(true);
    const binHidden = ref(true);
    const router = useRouter();
    const addressSuggestionComputedBus = computed(
      () => businessaddressSuggestions.value
    );
    const intialValues = {
      businessName: "",
      businessIdentificationNumber: "",
      gst_hstNumber: "",
      address1: "",
      address2: "",
      province: "",
      city: "",
      postalCode: "",
    };

    const isMobile = () => {
      return checklistMethods.isMobile();
    };

    const schema = yup.object().shape({
      businessName: yup.string().trim().required("Business Name is required"),
      businessIdentificationNumber: yup
        .string()
        .trim()
        .required("Business Identification number is required"),
      gst_hstNumber: yup.string().trim().required("GST/HST number is required"),
      address1: yup.string().trim().required("Address 1 is required"),
      address2: yup.string().trim(),
      province: yup.string().trim().required("Province is required"),
      city: yup.string().trim().required("City is required"),
      postalCode: yup.string().trim().required("Postal Code is required"),
    });

    const autocompleteGoogle = (searchText: string) => {
      let input = searchText;
      let type = "address";
      proxyApi
        .post(
          "/placeAutocomplete",
          encodeWithParam({ inputval: input, type: type })
        )
        .then((data) => {
          businessaddressSuggestions.value = data.data.result.predictions.map(
            (item) => {
              return {
                name: `${item.description}`,
                item: `${item.place_id}`,
              };
            }
          );
        });
    };

    const viewBin = computed(() => (binHidden.value ? "password" : "text"));

    const toggleEyeBin = () => {
      binHidden.value = !binHidden.value;
    };

    const selectedAddress = (selectedAddress: any, setFieldValue: any) => {
      let placeID = selectedAddress.item;
      proxyApi
        .post("/getPlaceDetails", encodeWithParam({ place_id: placeID }))
        .then((data: any) => {
          let addressComponents = data.data.result.result.address_components;
          if (addressComponents.length > 0) {
            const province =
              addressComponents.find((component) =>
                component.types.includes("administrative_area_level_1")
              )?.long_name || "";
            const address1 =
              (addressComponents.find((component) =>
                component.types.includes("street_number")
              )?.long_name || "") +
              " " +
              (addressComponents.find((component) =>
                component.types.includes("route")
              )?.long_name || "");
            const city =
              addressComponents.find((component) =>
                component.types.includes("locality")
              )?.long_name || "";
            const PostalCode =
              addressComponents.find((component) =>
                component.types.includes("postal_code")
              )?.short_name || "";

            setFieldValue("address1", address1);
            setFieldValue(
              "city",
              city.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            );
            setFieldValue(
              "province",
              province.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            );
            setFieldValue("postalCode", PostalCode);

            if (PostalCode == "" || PostalCode.length < 5) {
              enableEdit.value = false;
            } else {
              enableEdit.value = true;
            }
          }
        });
    };

    const handleForm = (formData: any) => {
      proxyApi
        .post("/saveBusinessDetails", encodeWithParam(formData))
        .then(() => {
          router.push({ name: "CaProfile" });
        });
    };
    const navigateBack = () => {
      router.push({ name: "CaProfile" });
    };
    return {
      isMobile,
      schema,
      handleForm,
      autoComplete,
      addressSuggestionComputedBus,
      autocompleteGoogle,
      selectedAddress,
      enableEdit,
      intialValues,
      binHidden,
      toggleEyeBin,
      viewBin,
      navigateBack,
    };
  },
});

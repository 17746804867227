
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  computed,
} from "vue";
import { Form, Field, configure } from "vee-validate";

import {
  IneligibleModal,
  FieldError,
  // WalmartTaxConsentPolicy,
  StateSelectorValidated,
  TextFieldValidated,
} from "@/components";
import * as yup from "yup";
import micromodal from "micromodal";
import {IconEyeClosed, IconEyeOpen } from "@/components";
import { DriversLicenseValidation } from "@/util/DriverLicenseValidation";
import { DateTime } from "luxon";
import { state } from "@/harlem/stores";
import { mutation } from "@/harlem/mutation";
import { useRoute, useRouter } from "vue-router";
// import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import DateDropDown from "@/components/forms/DateDropDown.vue";
import Autocomplete from "vue3-autocomplete";
import { isCountyRequired } from "@/util/AddressUtilities";
import Base64 from "base-64";
import { proxyApi, restApi, kycApi } from "@/http/http.config";
import { decrypt, encodeWithParam, encrypt } from "@/util/DataEncodeDecode";
import InitChecklist from "../components/Checklist/InitChecklist.vue";
import checklistMethods from "../components/Checklist/Helpers/Index";
import { setStatus } from "@/services/stage.service";
import {
  nullAddress,
  taxClassifications,
} from '@/modules/signup/personalDetails/constants'
import {
  AddressData,
  Suggestion
} from '@/modules/signup/personalDetails/interfaces'
import { selectHttpOptionsAndBody } from "@apollo/client/core";
import { validations } from "@/util/Validations";
import { gTagEvent } from "@/util/GoogleAnalytics";
import CommonService from "@/services/common.service";
import { setBusinessFlag } from "@/harlem/mutation/business.mutation";

export default defineComponent({ 
  name: "ca-personalInfo",
  props: ['driversEmail','driversDetails'],
  components: {
    FieldError,
    IneligibleModal,
    StateSelectorValidated,
    TextFieldValidated,
    Field,
    Form,
    DateDropDown,
    IconEyeClosed, IconEyeOpen
  },
  data() {
    return {
      isDobError: false,
      isExpiryError: false,
      cssnError: false,
    };
  },
  setup(props) {
     
      const viewPassword = computed(() => {
      return passwordHidden.value ? "password" : "text";
    });
      const togglePassword = () => (passwordHidden.value = !passwordHidden.value); 

    configure({
      validateOnModelUpdate: true,
    });
    
    const passwordHidden = ref(true);
    let ssnHidden = ref(true);
    let einHidden = ref(true);
    let cssnHidden = ref(true);
    const toggleEyeSsn = () => { ssnHidden.value = !ssnHidden.value }
    const toggleEyeEin = () => { einHidden.value = !einHidden.value }
    const ctoggleEyeSsn = () => { cssnHidden.value = !cssnHidden.value }

    const viewSsn = computed(() => ssnHidden.value ? "password" : "text");
    const viewEin = computed(() => einHidden.value ? "password" : "text");
    const cviewSsn = computed(() => cssnHidden.value ? "password" : "text");
    const ssnInputMask = computed(() => ssnHidden.value ? "#########" : "###-##-####")
    const cssnInputMask = computed(() => cssnHidden.value ? "#########" : "###-##-####")
    const einMask = computed(() => einHidden.value ? "#########" : "##-#######")

    let licenseHidden = ref(true)
    const toggleEyeLicense = () => { licenseHidden.value = !licenseHidden.value }
    const viewLicense = computed(() => licenseHidden.value ? "password" : "text");
    const selectedBusinessState = ref('')
    const selectedState = ref('');
    const oldestAllowed = DateTime.utc()
      .minus({ years: 18, days: 1 })
      .toJSDate();
    const currentDate = DateTime.utc().toJSDate();
    const valid = ref(false);
    const loading = ref(false);
    const addressDisable = ref(true);
  //   const autoComplete = ref<InstanceType<typeof Autocomplete>>();
    const statePersonalSelected = ref<InstanceType<typeof StateSelectorValidated>>();
    const stateBusinessSelected = ref<InstanceType<typeof StateSelectorValidated>>();
    const showQuestions = ref(true);
    const suggestionText = ref(true);
    const warningText = ref(false);
    const responseSSN = ref({ ssn: "", boolean: true });
    const responseLicense = ref({ license: "", boolean: true });
    const router = useRouter();
    const isBusiness = ref(state.business.isBusinessSelected);
    const suggestions = ref<Suggestion[]>([])
    const businessaddressDetails = ref<AddressData>(nullAddress)
    const otherTaxClass = ref(false)
    const einValidmess = ref('')
    const isStateSelected = ref(false)
    const businessaddressDisable = ref(true)
    const eTaxErr = ref(false)
    const eTaxOpen = ref(false);
    const newOne = ref(false);
    const eTaxError = ref('')
    const disableAdd2 = ref(false);
    const restAdd2 = ref(["pobo", "pobox"]);
    const restrictedAddress = ref(["pob", "pobo", "pobox", "bo", "box", "PO", "PO", "BOX", "PO B", "PO BO", "P.O. BOX", "P.O BOX", "PO. BOX", "POBOX", "PO BOX", "PO    BOX", "P.O BO", "PO. B", "P O Box", " PO  BOX", "PO BOX", "PO  B", "PO  BO", "P O BOX", "P O B", "P OB", "P O B", "P.O. B", "PO B", "P O", "P O", "P.O", "P.O ", "P.O  ", " P.O", "  P.O", "P.O B", "P.O  B", "P.O.  BO", "P.O.  BOX", "P.O.  B", " P.O.", "  P.O.", "  P.O. B", "  P.O. BO", "  P.O.  BOX", "p.o", "po", "p.o.", " po", "  po", "  po box", "p o", " p.o.", "p o box", "P.o", "p.O", "Po", "p.O"])
    const tworestrictedAddress = ref(["p", "pob", "pobo", "pobox", "bo", "box", "PO", "PO", "BOX", "PO B", "PO BO", "P.O. BOX", "P.O BOX", "PO. BOX", "POBOX", "PO BOX", "PO    BOX", "P.O BO", "PO. B", "P O Box", " PO  BOX", "PO BOX", "PO  B", "PO  BO", "P O BOX", "P O B", "P OB", "P O B", "P.O. B", "PO B", "P O", "P O", "P.O", "P.O ", "P.O  ", " P.O", "  P.O", "P.O B", "P.O  B", "P.O.  BO", "P.O.  BOX", "P.O.  B", " P.O.", "  P.O.", "  P.O. B", "  P.O. BO", "  P.O.  BOX", "p.o", "po", "p.o.", " po", "  po", "  po box", "p o", " p.o.", "p o box", "P.o", "p.O", "Po", "p.O"])



    if (sessionStorage.getItem("white_label") === "customCss") {
      showQuestions.value = false;
    }
    // const { updatePartnerApplication, saveCandidateProgress } = signupComposable();
    const route = useRoute();
    const locationId = route.query.locationId;
    const populate = route.query.populate
    let addressSuggestions: any = ref([]);
    let businessaddressSuggestions: any = ref([]);
    const personalDetails = reactive({}) as {
      [key: string]: string | boolean | Date | null | undefined;
    };
    personalDetails.dateOfBirth = "";
    personalDetails.license = "";
    personalDetails.licenseState = "";
    personalDetails.licenseExpiry = "";
    personalDetails.ssn = "";
    personalDetails.address1 = "";
    personalDetails.address2 = "";
    personalDetails.city = "";
    personalDetails.state = "";
    personalDetails.county = "";
    personalDetails.zip = "";
    personalDetails.businessName = "";
    personalDetails.ein = "";
    personalDetails.businessaddress1 = "";
    personalDetails.businessaddress2 = "";
    personalDetails.businesscity = "";
    personalDetails.businessstate = "";
    personalDetails.businesscounty = "";
    personalDetails.businesszip = "";
    personalDetails.taxClassification = "";
    personalDetails.taxClassificationOther = "";
    const initialTouched = reactive({}) as { [key: string]: boolean };

    onMounted(async () => { 
      let driversDetails = JSON.parse(props.driversDetails);
      // let driversDetails = props.driversDetails;
      // console.log(driversDetails, "RESULT")
      const progress: any = localStorage.getItem("progress")
      const progressObj = JSON.parse(progress)

      if(driversDetails != undefined){ 
        personalDetails.firstName = driversDetails.firstName;
        personalDetails.lastName = driversDetails.lastName;
        personalDetails.dateOfBirth = driversDetails.dateOfBirth;
        personalDetails.licenseExpiry = driversDetails.licenseExpiry;
        personalDetails.licenseNumber = driversDetails.licenseNumber;
        personalDetails.state = driversDetails.state;
      }
     
      if (JSON.parse(localStorage.getItem("isBusiness") || "false")) {
        isBusiness.value = true
      }

     
      selectedState.value = '';
      setStatus("PERSONAL_DETAILS", "INCOMPLETE")
      

      gTagEvent("Show SSN Tooltip", "Personal Details", `partner_id: ${state.user.partnerId}`)
    });
  
   

   

    const schema = yup.object().shape({
      dateOfBirth: yup
        .date()
        .required("Date of Birth is required")
        .label("Date of Birth")
        .typeError("Date of birth is required") // D
        .max(oldestAllowed, "Age should not be less than 18 years"),
     
      licenseNumber: yup
        .string()
        .trim("License number can't contain white space at the start or end")
        .strict(true)
        .required("Driver's license number is required")
        .test("licenseNumber", "Spacial charecters are not allowed",
          function (value: any) {
            const hasSpecialChar = /[^0-9a-zA-Z\s'-]/
            if (hasSpecialChar.test(value)) {
              disableAdd2.value = true
              return false

            } return true;
          }),
      
      licenseExpiry: yup
        .date()
        .required("DL expiration date is required")
        .label("License Expiration")
        .min(new Date(), "DL expiration date is required")
        .typeError("DL expiration date is required"),
      
       
      
      state: yup.string().trim().required("Province is required").label("Province"),
      
     

      firstName: yup
        .string()
        .required("First Name is required"),
      lastName: yup
        .string()
        .required("Last Name is required"),  
     
    
    });

    
    

    const updateDetails = (detail, e) => {
      personalDetails[detail] = e;
    }

  
    const autoCompleteError = (errors: any) => errors.address1 ? ['has-error'] : []
    const addressSuggestionComputed = computed(() => addressSuggestions.value);
  


    const saveDetails = async (data: any) => {
      data.licenseNumber = data.licenseNumber.replaceAll('-','')
      let dataTosend = data;
      dataTosend.email = sessionStorage.getItem('driversEmail');
      // console.log(dataTosend.email, "email value")
    //  if(props.driversEmail != undefined){
    //   // dataTosend.email = props.driversEmail;
    //   dataTosend.email = sessionStorage.getItem('driversEmail');
    //  }else{
    //   let completeData =JSON.parse(props.driversDetails);
    //   // console.log(completeData, "111111")
    //   dataTosend.email = completeData.email;
    //  }

    //  if(props.driversDetails != undefined){
    //   dataTosend = JSON.parse(props.driversDetails);
    //  }
      //  console.log(dataTosend, "data to send")

      sessionStorage.setItem('driversDetails',JSON.stringify(dataTosend));

      return router.push({
                  name: "personalInfoConfirmation",
                  params: {
                    driversDetails: JSON.stringify(dataTosend)
                  }
                });
      
    };

    const updateStateValue = (category, e: any, setFieldError: any) => {
      if (category == "personal") {
        selectedState.value = e.target.value
        if (selectedState.value === 'CA') {
          setFieldError('county', 'County is required')
        } else {
          setFieldError('county', '')
        }
      } else if (category == "business") {
        selectedBusinessState.value = e.target.value
        if (selectedBusinessState.value === 'CA') {
          setFieldError('county', 'County is required')
        } else {
          setFieldError('county', '')
        }
      }
    }

    return {
      passwordHidden,
      togglePassword,
      viewPassword,
      suggestions,
      taxClassifications,
      ssnHidden,
      toggleEyeSsn,
      viewSsn,
      licenseHidden,
      toggleEyeLicense,
      viewLicense,
      ssnInputMask,
      einMask,
      saveDetails,
      initialTouched,
      personalDetails,
      valid,
      state,
      schema,
      currentDate,
      oldestAllowed,
      loading,
      showQuestions,
      addressSuggestions,
      // getAddressSuggestion,
      // selectedAddress,
      addressDisable,
      // autoComplete,
      stateBusinessSelected,
      statePersonalSelected,
      updateStateValue,
      selectedState,
      selectedBusinessState,
      autoCompleteError,
      addressSuggestionComputed,
      suggestionText,
      warningText,
      // handleTaxSelect,
      // handleOtherTaxSelect,
      // handleStateSelect,
      updateDetails,
      micromodal,
      isBusiness,
      // handleOpenSSNModal,
      // toggleBusiness,
      // walmartTaxConfirmation,
      // getTaxConsent,
      // findSuggestions,
      // selectSuggestion,
      otherTaxClass,
      businessaddressDisable,
      // openModal,
      eTaxError,
      eTaxErr,
      disableAdd2,
      viewEin,
      einHidden,
      toggleEyeEin,
      cssnHidden,
      ctoggleEyeSsn,
      cviewSsn,
      cssnInputMask,
      eTaxOpen,
      newOne
    };
  },
  methods: {
   redirectTONext: function(){
    return this.$router.push({
                name: "personalInfoConfirmation",
              });
  },
    dateValidate: function (data) {
      this.isDobError = false;
      let age = this.underAgeValidate(data);
      if (data.length === 10) {
        this.personalDetails.dateOfBirth = data
      }
      if (age <= 18) {
        this.isDobError = true;
      }
    },

   

    expirydateValidate: function (data) {
      this.isExpiryError = false;
      let futuredate = this.isFutureDate(data);
      if (data.length === 10) {
        this.personalDetails.licenseExpiry = data
      }
      if (!futuredate) {
        this.isExpiryError = true;
      }
    },
    underAgeValidate: function (birthday) {
      const end = DateTime.now();
      const start = DateTime.fromISO(birthday);
      const diffInMonths = end.diff(start, "months");
      let age = diffInMonths.months / 12;
      return age;
    },
    isFutureDate: function (dateVal) {
      const g1 = new Date();
      const g2 = new Date(dateVal);
      if (g1.getTime() < g2.getTime()) return true;
      else if (g1.getTime() > g2.getTime()) return false;
    },
    displayAddressSuggestion: function (item) {
      return item.streetLine;
    },
    isMobile() {
      return checklistMethods.isMobile();
    },
    triggerHyphenCheck: function (e) {
      if ((e.key === "Backspace") && e.target.value.length > 0) {
        var str = e.target.value;
        if ("-" == str.charAt(str.length - 1)) {
          str = str.substring(0, str.length - 1);
        }
        e.target.value = str;
      }
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z]+$/.test(char)) return true;
      else e.preventDefault();
    },
  },
});


  import { defineComponent, ref, computed, onMounted, reactive } from "vue";
import authenticationService from "@/services/authentication.service";
  
  export default defineComponent({
    name: "kycFail",
    components: {
      
    },
   methods: {
    redirectTONext: function(){
      authenticationService.logout();
      return this.$router.push({
                  name: "ca-login",
                });
    },
   }
  });
  
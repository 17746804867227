
import { defineComponent, reactive ,ref } from 'vue';
import { Form } from 'vee-validate';
import TextFieldValidated from '@/components/forms/TextFieldValidated.vue';
import Base64 from "base-64";
import { signup } from "@/http/http.config";
import * as yup from "yup";
import micromodal from "micromodal";
import { setStatus } from "@/services/stage.service";
import { encode, encodeWithParam } from "@/util/DataEncodeDecode";
import { duplicatePhone } from "@/components";
import { useRoute, useRouter } from "vue-router";
import walmartAssociateModal from '@/modules/signup/signup/walmartAssociateModal.vue';



export default defineComponent({
    name:'PhoneVerification',
    components:{
        Form,
        TextFieldValidated,
        duplicatePhone,
        walmartAssociateModal
    },
    data(){
        return{

            check1:false,
            check2:false,
           
            
        }
    },
    methods:{



    },
    setup() {
    const route = useRoute();
    const router = useRouter();
    const locationId = route.query.locationId;
    const locationName = route.query.name;
    const phoneError =ref(false);
    // const campaign = route.query?.campaign || null;
    // const medium = route.query?.medium || null;
    // sessionStorage.removeItem("signupData");
    const initialValues = reactive({
        phoneNo: "",
    //   isOver18: false,
    //   isNotOver18: false,
      approvedConsent: false,
    //   otp: "",
      time: '',
      incorrectOTP: false,
      incorrectNumber: false,
    //   isEmployee:false,
    //   isNotEmployee:false
    });
    // configure({
    //   validateOnInput: true,
    // });
    const schema = yup.object().shape({
        phoneNo: yup
        .string()
        .strict(true)
        // .min(14, "Invalid phone number")
        // .required("Phone number is required")
        // .test("phone", function (value: any) {
        //   let plainPhone = value.replace(/\D/g, "");
        //   const pn = new PhoneNumber(plainPhone, "US");
        //   if (!pn.isValid()) {
        //     return this.createError({
        //       message: "Invalid phone number",
        //     });
        //   } else return true;
        // })
        .label("Phone"),
      OTP: yup
        .string()
        .required("OTP is required")
        .label("OTP")
        // .min(5, "Invalid OTP")
    })
    const handleConsent =()=>{
        initialValues.approvedConsent =!initialValues.approvedConsent;
        if(initialValues.approvedConsent){
            console.log("handleConsent")
        }
    }
    const otpText =ref("")
    const active=ref(false);
    const defaltCheckValue=ref(0);
    const handleCheckboxChange=(checkboxNumber)=> {
        defaltCheckValue.value = checkboxNumber;
        if(defaltCheckValue.value===2){
            active.value=true;
        }else{
            active.value=false;
        }
    }
    const defaltCheckSecond=ref(0);
    const handleSecond=(checkboxNumber)=>{
        defaltCheckSecond.value = checkboxNumber;
    }
    const phoneNumber=(phoneNo)=>{
            initialValues.phoneNo=phoneNo;
        }
    const resetCheckboxResponse =()=> {
        initialValues["approvedConsent"] = true

    }
    const phonePattern=(value)=>{
        let phoneRegex=/^1\d{10}$/gm;
            if(phoneRegex.test(value.replace(/\D/g, ""))){
                 phoneError.value=false
            }else {
                phoneError.value=true
            }
    }
    const updateTime=(time) =>{
      initialValues.time = time
    }
    const handleResendOtp=()=> {
      let seconds = 5 * 60
      updateTime('5:00')
      let timer = setInterval(() => {
        if (seconds <= 0) {
          clearInterval(timer)
          updateTime('')
        } else {
          seconds--
          let time = Math.floor(seconds/60) + ':' + (seconds%60).toString().padStart(2, '0')
          updateTime(time)
        }
      }, 1000)
    }
    const OtpReceived=ref(false);
    const validatePhoneCa=(formData:any)=>{
            // /check phone api
             
             if(!OtpReceived.value){
                if(!initialValues.time){
                    handleResendOtp()
                }generateOTPCa(formData.phoneNo.replace(/\D/g, ""))
             }else{
                validateOTP(formData.phoneNo,formData.OTP)
             }
        }
    //     const validatePhoneNo=(value)=> {
    //         signup.post("/checkPhoneCa", { param: Base64.encode(JSON.stringify({ phoneNo: '+'+value })) }).then((data) => {
    //             if (data.data.rowCount >0) {
    //              return micromodal.show('duplicate-phone');
    //             } else {
    //                 generateOTPCa(value);
    //     }
    //   });
    // }
    const  generateOTPCa=(phoneNo)=>{
            let obj = { phoneNo: phoneNo.replace(/\D/g, "") };
    //   this.initialValues["incorrectNumber"] = false
      signup.post("/generateCAOTP", encodeWithParam(obj)).then((data) => {
        otpText.value = "";
        OtpReceived.value = true;
        if (data?.data?.success) {
          otpText.value = JSON.stringify(data?.data?.message);
        } else if (!data?.data?.success) {
        //   this.initialValues["incorrectNumber"] = true
          OtpReceived.value = false;
        }
    });
        }
    const validateOTP=(phoneNo, otp)=> {
      initialValues["incorrectOTP"] = false
      let obj = {
        phone: phoneNo.replace(/\D/g, ""),
        otp: otp
      };
      sessionStorage.setItem("signupData_phone", encode('+'+obj.phone));
      signup.post("/validateOTP", encodeWithParam(obj)).then((data) => {
        if (data.data.success) {
  
                signup.post("/checkPhoneCa", { param: Base64.encode(JSON.stringify({phoneNo : '+'+obj.phone}))}).then((data)=>{
                    if (data.data.rowCount >0) { 
                        router.push({
                  name: "ca-login",

                   });
                    } else {
                        router.push({
                  name: "CreateAccount",
                  query: {
                 locationId: locationId,
                 name: locationName,
                  },
                   }); 
                    }
                })
        //   setStatus('VERIFICATION', 'INCOMPLETE')
        } else {
          setTimeout(()=> {initialValues["incorrectOTP"] = false}, 3000)
          initialValues["incorrectOTP"] = true
        }
      });
    }


    const resendOTP=()=> {
      handleResendOtp()
      let phoneNo = initialValues.phoneNo.replace(/\D/g, "");
      generateOTPCa(phoneNo);
    }

    const  sendCodeDisable=(errors)=>{
            if(initialValues.approvedConsent && !phoneError.value && defaltCheckValue && defaltCheckSecond && !("phoneNo" in errors) && initialValues.phoneNo != "" && defaltCheckValue.value === 1 && defaltCheckSecond.value === 21  ){
                return false;
            }else{
                return true;
            }
        }

    return {
      initialValues, 
      schema,
      handleConsent,
      router,
      locationId,
      locationName,
      sendCodeDisable,
      resetCheckboxResponse,
      phonePattern,
      phoneError,
      phoneNumber,
      handleCheckboxChange,
      active,
      defaltCheckValue,
      handleSecond,
      defaltCheckSecond,
      updateTime,
      handleResendOtp,
      validatePhoneCa,
      OtpReceived,
    //   validatePhoneNo,
      otpText,
      resendOTP
      
    }
  },
})

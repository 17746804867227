
import router from "@/router";
import { defineComponent, ref } from "vue";
import { proxyApi } from "@/http/http.config";
import Base64 from "base-64";
import { IconWarningOctagon } from "@/components";

export default defineComponent({
  name: "RestPassword",
  emits: ["email"],
  components: {
    IconWarningOctagon
  },
  setup(props, {emit} ) {

    const userNotFound = ref(false)
const createUsername = async () => {
    const userId = window.location.search.split("?")[1]
    const decryptedEmail =  Base64.decode(userId)
    const username = JSON.parse(decryptedEmail);
    const result = await proxyApi.post("/checkEmail", { param: Base64.encode(JSON.stringify({email: username.userId}))});
     if (result.data.result.length > 0 && result.data.result[0].userID != null) {
       router.push({ path: '/otp-verify', query: {userId}})
        }
        else{
          userNotFound.value = true
        }
}
return { 
      createUsername,
      userNotFound
    };
  },
});

export default [
  // {
  //   path: "/dashboard",
  //   name: "dashboard",
  // component: () => import("@/modules/dashboard/Dashboard.vue"),
  //   meta: { title: "Delivery Drivers Inc", noHeader: false, layout: "dashboard"},
  // },
  // {
  //   path: "/earnings",
  //   name: "earnings",
  //   component: () => import("@/modules/earnings/Index.vue"),
  //   meta: { title: "Delivery Drivers Inc | Deposit-Summaries", noHeader: false, layout: "dashboard"},
  // },
  {
    path: "/manage-earnings",
    name: "manage-earnings",
    component: () => import("@/modules/earnings/ManageEarnings.vue"),
    meta: { title: "Delivery Drivers Inc | Manage Earnings", noHeader: false, layout: "dashboard"},
  },
  // {
  //   path: "/deposit-summaries",
  //   name: "deposit-summaries",
  //   component: () => import("@/modules/earnings/DepositSummaryHome.vue"),
  //   meta: { title: "Delivery Drivers Inc | Deposit-Summaries", noHeader: false, layout: "dashboard"},
  // },
  {
    path: "/earnings/summaries",
    name: "earningsSummaries",
    component: () => import("@/modules/earnings/Summaries.vue"),
    meta: { title: "Delivery Drivers Inc", noHeader: false , layout: "dashboard"},
  },
  // {
  //   path: "/earnings/deposit-summary/:paystubId",
  //   name: "depositSummary",
  //   component: () => import("@/modules/earnings/DepositSummary.vue"),
  //   meta: { title: "Delivery Drivers Inc", noHeader: false , layout: "dashboard"},
  // },
  // {
  //   path: "/earnings/one-summary",
  //   name: "oneDepositSummary",
  //   component: () => import("@/modules/earnings/OneSummary.vue"),
  //   meta: { title: "Delivery Drivers Inc", noHeader: false , layout: "dashboard"},
  // },
  // {
  //   path: "/earnings/one-summary/day-breakup",
  //   name: "OneDaySummary",
  //   component: () => import("@/modules/earnings/OneDaySummary.vue"),
  //   meta: { title: "Delivery Drivers Inc", noHeader: false , layout: "dashboard"},
  // },
  {
    path: "/perks",
    name: "perks",
    component: () => import("@/modules/dashboard/Perks.vue"),
    meta: { title: "Delivery Drivers Inc | Perks" , layout: "dashboard"},
  },
  // {
  //   path: "/profile",
  //   name: "profile",
  //   component: () => import("@/modules/profile/Profile.vue"),
  //   meta: { title: "Delivery Drivers Inc | Profile", noHeader: false , layout: "dashboard"},
  // },

  // {
  //   path: "/profile/business-register",
  //   name: "NewBusinessRegister",
  //   component: () => import("@/modules/profile/NewBusinessRegister.vue"),
  //   meta: { title: "Delivery Drivers Inc | Profile", noHeader: false , layout: "dashboard"},
  // },

  // {
  //   path: "/profile/business-register/business-w9",
  //   name: "profile-w9",
  //   component: () => import("@/modules/profile/W9FormNew.vue"),
  //   meta: {
  //     title: "Delivery Drivers Inc | W9",
  //     noHeader: false,
  //     authgaurd:false,
  //     noSignupNav: false,
  //     layout: "dashboard",
  //   }
  // },
  // {
  //   path: "/profile/edit",
  //   name: "profileEdit",
  //   component: () => import("@/modules/profile/EditProfile.vue"),
  //   meta: { title: "Delivery Drivers Inc | Edit Profile", noHeader: false , layout: "dashboard"},
  // },
  // {
  //   path: "/settings",
  //   name: "settings",
  //   component: () => import("@/modules/settings/Settings.vue"),
  //   meta: { title: "Delivery Drivers Inc | Settings" , noHeader: false, layout: "dashboard"},
  // },
  // {
  //   path: "/documents",
  //   name: "dashboardDocuments",
  //   component: () => import("@/modules/documents/Documents.vue"),
  //   meta: { title: "Delivery Drivers Inc | Documents" , layout: "dashboard"},
  // },
  {
    path: "/appeals",
    name: "appeals",
    component: () => import("@/modules/profile/MakeAppeal.vue"),
    meta : {
      title: "Appeals",
      layout: "dashboard",
      noHeader: false
    }
  },
  // {
  //   path: "/documents/tax",
  //   name: "taxDocuments",
  //   component: () => import("@/modules/documents/TaxDocuments.vue"),
  //   meta: { title: "Delivery Drivers Inc", layout: "dashboard" },
  // },
  // {
  //   path: "/documents/signed",
  //   name: "signedDocuments",
  //   component: () => import("@/modules/documents/SignedDocuments.vue"),
  //   meta: { title: "Delivery Drivers Inc", layout: "dashboard" },
  // },
  // {
  //   path: "/documents/personalDocuments",
  //   name: "personalDocuments",
  //   component: () => import("@/modules/documents/PersonalDocuments.vue"),
  //   meta: { title: "Delivery Drivers Inc", layout: "dashboard" },
  // },
  // {
  //   path: "/candidate/dashboard",
  //   name: "candidateDashboard",
  //   component: () => import("@/modules/funnelStatus/FunnelStatus.vue"),
  //   meta: { title: "Delivery Drivers Inc", noHeader: false, layout: "dashboard" },
  // },
  {
    path: "/privacystatement",
    name: "privacystatement",
    component: () => import("@/modules/dashboard/PrivacyStatement.vue"),
    meta: { title: "Delivery Drivers Inc", noHeader: false, layout: "dashboard", open:true },
  },
];


  import { defineComponent, ref, onMounted, computed } from "vue";
  import { proxyApi } from "@/http/http.config";
  import { encodeWithParam } from "@/util/DataEncodeDecode";
  import checklistMethods from "../../signup/components/Checklist/Helpers/Index";
  import { useRoute, useRouter } from 'vue-router'
  import micromodal from "micromodal";
  import { DateTime } from "luxon";
 
  import { gTagEvent } from "@/util/GoogleAnalytics";
  import { state } from "@/harlem/stores";
  import FunnelStatus from "../../funnelStatus/FunnelStatusWidget.vue";
  import { getHiddenEarningAccounts, getOneStatus } from "../../funnelStatus/Helpers";
  import { disableEarningsTab } from "@/router/Helpers";
  
  export default defineComponent({
    name: "ca-dashboard",
    components: {
    },
    setup() {
      const message  = sessionStorage.getItem('statusWarningMessage')
      const firstName = ref(state.user.firstName)
      const partnerLocation = state.user.locationName
      const cardBodyArray: any = []
      const isLoading = ref(true)
      const router = useRouter();
      const route = useRoute();
      const allowTaxesDownload = ref(true);
      const pendingNumber = ref(1);
      const unfinishedNumber = ref(0);
      const docsToSign = ref(false);
      const unfinished = ref(false);
      const progressStage = ref(false);
      const onePaymentDate = DateTime.fromISO(state.user.oneStartDate).toFormat('MM/dd/yyyy')
      const oneStatus:any = ref(null);
      const garnishment = state.user.garnishment
      const oneIntegration = state.user.isOneIntegrated;
      const isOneActive = state.user.isActive;
      const payeeID = sessionStorage.getItem('payeeID')
      const oneSignUp = route.query.oneSignUp
      const branchStatus = ref("")
      const noActiveAccounts = ref(true)
      const isEarningsDisabled = ref(true)
      const attemptedEarningAccount = ref(false)
      const oneEnabled = ref(false)
      const onePaymentStarted = computed(() => {
        return DateTime.now().toFormat('MM/dd/yyyy') > onePaymentDate ? true : false;
      });
  

  
      const redirectTONext = () => {
        const flag = isMobileCheck();
        if(message == "Earning account setup incomplete"){
          sessionStorage.setItem("cardType","EARNINGS_ACCOUNT")
          sessionStorage.setItem("progress","EARNINGS_ACCOUNT")
          if(flag){
            return router.push({
                name: "checklist",
              });
          }else{
            return router.push({
                name: "PersonalDirectDeposit",
              });
          }
          
        }else if(message == "Personal information incomplete"){
          sessionStorage.setItem("cardType","PERSONAL_DETAILS")
          sessionStorage.setItem("progress","PERSONAL_DETAILS")
          if(flag){
            return router.push({
                name: "checklist",
              });
          }else{
            return router.push({
                name: "fullProfileDetails",
              });
          }
        }else if(message == "Insurance upload incomplete"){
          sessionStorage.setItem("cardType","INSURANCE_UPLOAD")
          sessionStorage.setItem("progress","INSURANCE_UPLOAD")
          if(flag){
            return router.push({
                name: "checklist",
              });
          }else{
            return router.push({
                name: "UploadCardDetails",
              });
          }
        }
       
      }
      // const handleSubmit = (choice: string) => {
      //   micromodal.close('modal-tax-consent')
      //   proxyApi.post('/taxConsent', encodeWithParam({
      //     taxConsent: choice,
      //     signedOn: new Date(),
      //   }))
      //   if (choice === 'false') micromodal.show('tax-confirm-address')
      // }
  
      const handleConfirm = (choice: string) => {
        micromodal.close('tax-confirm-address')
        if (choice === 'edit') router.push({ name: 'profile' })
      }
  
      const gTagONE = () => {
        gTagEvent("Dashboard Sent to Create ONE Account", "ONE", true)
      }
  
      const getBranchStatus = () => {
        proxyApi.get('/getWalletStatus').then(({ data }) => {
          if (data.result.length > 0) {
            const status = data.result[0]?.status
            branchStatus.value = status
          }
        })
      }
  
      const isAttemptedEarningAccount = async () => {
        attemptedEarningAccount.value = await getHiddenEarningAccounts()
      } 
  
      const showOneValuePropBanner = () => {
        let validONEStatus, hasPayeeID;
        validONEStatus = (['STARTED', '', undefined].includes(oneStatus.value));
        hasPayeeID = payeeID !== 'null';
        const showBanner = (validONEStatus && !garnishment && hasPayeeID) && oneEnabled.value == false
        return showBanner
      }
  
     const isMobileCheck = () => {
      return checklistMethods.isMobile();
     }
  
      return {
        message,
        partnerLocation,
        redirectTONext,
        isLoading,
        unfinishedNumber,
        pendingNumber,
        docsToSign,
        progressStage,
        unfinished,
        allowTaxesDownload,
        // handleSubmit,
        handleConfirm,
        micromodal,
        oneStatus,
        garnishment,
        oneEnabled,
        oneIntegration,
        onePaymentStarted,
        isOneActive,
        payeeID,
        gTagONE,
        onePaymentDate,
        oneSignUp,
        router,
        isEarningsDisabled,
        branchStatus,
        showOneValuePropBanner,
        cardBodyArray,
        firstName,
        isMobileCheck
      };
    },
    methods: {
      isMobile() {
        return checklistMethods.isMobile();
      },
    }
  });
  

import { defineComponent, reactive, ref, computed } from "vue";
import checklistMethods from "../components/Checklist/Helpers/Index";
import router from "@/router";
import { encodeWithParam } from "@/util/DataEncodeDecode";
import { Field } from "vee-validate";
import { IconArrowLeft } from "@/components";
import { mutation } from "@/harlem/mutation";
import { proxyApi } from "@/http/http.config";
import GoogleAuto from "../googleAuto.vue";

export default defineComponent({
  name: "CaProfile",
  components: {
    IconArrowLeft,
    Field,
    GoogleAuto,
  },
  methods: {
    isMobile() {
      return checklistMethods.isMobile();
    },
    checkDmsId():any{
      return sessionStorage.getItem('payeeID') !== 'null' ? true : false;
    },
  },
  mounted() {
    this.fetchPartnerData();
  },
  setup() {
    let partnerData = reactive({}) as {
      [key: string]: string | boolean | Date | null | undefined | any;
    };
    const bussDetail = computed(() => {
      if (partnerData.taxEligible) {
        return true;
      }
      return false;
    });
    const editEmail = ref(false);
    const editAddress = ref(false);
    const cancelEditBox = () => {
      editEmail.value = false;
      emailValidationMessage.value = "";
      fetchPartnerData();

    };
    const saveEditBox = (val) => {
      switch (val) {
        case "email":
          saveEmail();
      }
    };
    const addressData = ref({
      //not working
      id: "",
      address1: "",
      address2: "",
      state: "",
      city: "",
      county: "",
      PostalCode: "",
    });
    const businessAddressData = ref({
      id: "",
      address1: "",
      address2: "",
      state: "",
      city: "",
      county: "",
      PostalCode: "",
    });
    //const isBusinessRegistered=ref(false)
    const emailValidationMessage = ref("");
    const emailValidation = (value) => {
      emailValidationMessage.value = "";
      let regex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
      let start = /^([ ]+)/;
      let end = /([ ]+$)/;
      let space = /\s/;
      let ending = /^.*\.com$/;
      let startingchar = /^[^\W_].*/;
      if (value.length === 0) {
        return (emailValidationMessage.value = "Email cannot be blank");
      }
      if (!regex.test(value)) {
        return (emailValidationMessage.value = "Not a valid email address");
      }
      if (start.test(value) || end.test(value) || space.test(value)) {
        return (emailValidationMessage.value = "Email cannot include spaces");
      }
      if (!ending.test(value) || !startingchar.test(value)) {
        return (emailValidationMessage.value = "Not a valid Email address");
      } if(partnerData.dbEmail != value && regex.test(value)) {
        return editIsDisabled.value= false;
      }

      return (emailValidationMessage.value = "");
    };
    const editIsDisabled = ref(true);
    const saveEmail = async () => {
      emailValidationMessage.value = "";
      partnerData.email == "";

      //editEmail.value=false
      if (partnerData.email != "") {
        let result = await proxyApi.post(
          "/checkEmail",
          encodeWithParam({ email: partnerData.email.toLowerCase() })
        );
        if (result.data.rowCount == 0 || (partnerData.email ==partnerData.username)) {
          mutation.user.setEmail(partnerData.email);
          proxyApi
            .post("/updateEmail", encodeWithParam({ email: partnerData.email }))
            .then(() => {
              editEmail.value = false;
              return partnerData.email;
            });
        } else {
          emailValidationMessage.value = "This record already exists";
          editEmail.value = true;
        }
      }
    };
    const cancelFunct = () => {
      editAddress.value = false;
    };
    const cancelBusFunct = () => {
      editBusinessAddress.value = false;
    };
    const saveaddHome = () => {
      editAddress.value = false;
      fetchPartnerData();
    };
    const saveBusadd = () => {
      editBusinessAddress.value = false;
      fetchPartnerData();
    };
    const editButton = ref(false)
    const fetchPartnerData = () => {
      proxyApi.get("/getFullProfileInfo").then(({ data }) => {
        //console.log(data.profile[0],'data fetchPartnerData')
        partnerData.username = data.profile[0].username;
        partnerData.extID = data.profile[0].externalID;
        partnerData.firstName = data.profile[0].firstName;
        partnerData.lastName = data.profile[0].lastName;
        partnerData.email = partnerData.dbEmail = data.profile[0].communication_email;
        partnerData.addressID = data.profile[0].addressID;
        partnerData.phone = data.profile[0].phone;
        partnerData.address1 = data.profile[0].address1 ;
        partnerData.address2 = data.profile[0].address2;
        partnerData.city = data.profile[0].city;
        partnerData.state = data.profile[0].state;
        partnerData.PostalCode = data.profile[0].postalCode;
        partnerData.taxEligible = data.profile[0].tax_eligible;
        partnerData.businessName = data.profile[0].businessName;
        partnerData.businessAddress1 = data.businessAddress[0]?.address1;
        partnerData.businessAddress2 = data.businessAddress[0]?.address2;
        partnerData.businesscity = data.businessAddress[0]?.city;
        partnerData.businesspostalcode = data.businessAddress[0]?.postalCode;
        partnerData.businessstate = data.businessAddress[0]?.state;
        //sessionStorage.setItem("addID", data.profile[0].addressID);
        addressData.value.id = data.profile[0].addressID;
        addressData.value.address1 = data.profile[0].address1;
        addressData.value.address2 = data.profile[0].address2;
        addressData.value.city = data.profile[0].city;
        addressData.value.state =data.profile[0].state;
        addressData.value.PostalCode = data.profile[0].postalCode;
        businessAddressData.value.address1 =data.businessAddress[0]?.address1;
        businessAddressData.value.address2 =data.businessAddress[0]?.address2;
        businessAddressData.value.city =data.businessAddress[0]?.city;
        businessAddressData.value.state =data.businessAddress[0]?.state;
        businessAddressData.value.PostalCode =data.businessAddress[0]?.postalCode;
        //console.log(partnerData.extID,"partnerData.extID")
        if(partnerData.extID){
          editButton.value = true;
        }


        if(partnerData.taxEligible) {
          businessAddressData.value.id = data.businessAddress[0]?.id;
        }
      });
    };
    const edit = (val) => {
      switch (val) {
        case "email":
          editEmail.value = true;
          break;
        case "address":
          editAddress.value = true;
          break;
        case "businessAddress":
          editBusinessAddress.value = true;
      }
    };
    const editBusinessAddress = ref(false);
    const businessRegister = () => {
      router.push({ name: "Ca-NewBusinessRegister" });
    };
    return {
      partnerData,
      fetchPartnerData,
      editEmail,
      edit,
      cancelEditBox,
      saveEditBox,
      emailValidationMessage,
      emailValidation,
      editAddress,
      cancelFunct,
      saveaddHome,
      bussDetail,
      businessRegister,
      editBusinessAddress,
      businessAddressData,
      saveBusadd,
      cancelBusFunct,
      addressData,
      editIsDisabled,
      editButton
    };
  },
});

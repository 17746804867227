
import Loader from "@/components/modals/Loader.vue";
import { IconArrowRight } from "@/components";
import { defineComponent, ref, onMounted, computed } from "vue";
import { proxyApi } from "@/http/http.config";
import { encodeWithParam } from "@/util/DataEncodeDecode";
import checklistMethods from "../../signup/components/Checklist/Helpers/Index";
import { useRoute, useRouter } from 'vue-router'
import micromodal from "micromodal";
import { DateTime } from "luxon";
import {
  TaxConsent,
  TaxConfirmAddress,
  WalmartTaxConsentPolicy,
  OneNotEligible,
} from "@/components";
import { gTagEvent } from "@/util/GoogleAnalytics";
import { state } from "@/harlem/stores";
import IconCheck from "../../../components/icons/IconCheck.vue";
import IconCrossAlert from "../../../components/icons/IconCrossAlert.vue";
import IconWarningInsurance from "../../../components/icons/IconWarningInsurance.vue";
import IconWarning from "../../../components/icons/IconWarning.vue";

// import FunnelStatus from "../../funnelStatus/FunnelStatusWidget.vue";
import { getHiddenEarningAccounts, getOneStatus } from "../../funnelStatus/Helpers";
import "../../../assets/css/components/checklist.scss"
import { bool } from "yup";

// import { disableEarningsTab } from "@/router/Helpers";
const getStep = sessionStorage.getItem("progress");
  const getStepStatus = JSON.parse(localStorage.getItem("progress") || "{}").status;
  const getFarthestStep = JSON.parse(localStorage?.getItem("step") || "{}");
export default defineComponent({
  name: "enrollment-checklist",
  components: {
    // OneNotEligible,
    // TaxConsent,
    // TaxConfirmAddress,
    // WalmartTaxConsentPolicy,
    // FunnelStatus,
    IconCheck,
    IconCrossAlert,
    IconWarningInsurance,
    IconWarning,
    IconArrowRight,
    Loader
    
  },
  setup() {
    const isLoading = ref(false);
    const driver_status = ref(false);
    const processingStatus = ref();
    const firstName = ref(state.user.firstName);
    const partnerLocation = state.user.locationName;
    const router = useRouter();
    const route = useRoute();
    const allowTaxesDownload = ref(true);
    const pendingNumber = ref(1);
    const unfinishedNumber = ref(0);
    const docsToSign = ref(false);
    const unfinished = ref(false);
    const progressStage = ref(false);
    const onePaymentDate = DateTime.fromISO(state.user.oneStartDate).toFormat('MM/dd/yyyy')
    const oneStatus:any = ref(null);
    const garnishment = state.user.garnishment
    const oneIntegration = state.user.isOneIntegrated;
    const isOneActive = state.user.isActive;
    const payeeID = sessionStorage.getItem('payeeID')
    const oneSignUp = route.query.oneSignUp
    const branchStatus = ref("")
    const noActiveAccounts = ref(true)
    const isEarningsDisabled = ref(false)
    const attemptedEarningAccount = ref(false)
    const oneEnabled = ref(false)
    const onePaymentStarted = computed(() => {
      return DateTime.now().toFormat('MM/dd/yyyy') > onePaymentDate ? true : false;
    });

   
    

    return {
      isEarningsDisabled,
      processingStatus,
      driver_status,
      firstName,
      unfinishedNumber,
      pendingNumber,
      docsToSign,
      progressStage,
      unfinished,
      allowTaxesDownload,
      // handleSubmit,
      // handleConfirm,
      micromodal,
      oneStatus,
      garnishment,
      oneEnabled,
      oneIntegration,
      onePaymentStarted,
      isOneActive,
      payeeID,
      // gTagONE,
      onePaymentDate,
      oneSignUp,
      router,
      branchStatus,
      // showOneValuePropBanner,
      partnerLocation,
      isLoading

    };
  },
  data() {
      return {
        
        ocr_status: "",
        ocr_flag: ref(true),
        currentStep: {
          label: getStep,
          stepIndex: checklistMethods.getIndexForStepper(getStep),
          status: getStepStatus,
        },
        cardGroupData: [
          {
            id: 1,
            title: "Enrollment forms",
            description: "",
            destination: ({ name: ""}),
            style: "checklist-row_num first-step",
            typename: "PERSONAL_DETAILS",
            status: "INCOMPLETE"
          },
          {
            id: 2,
            title: "Insurance verification",
            description: "",
            destination: "",
            style: "checklist-row_num",
            typename: "INSURANCE_UPLOAD",
            status: "INCOMPLETE"
          },
          {
            id: 3,
            title: "Background check",
            description: "",
            destination: ({ name: ""}),
            style: "checklist-row_num",
            typename: "BACKGROUND_CHECK",
            status: "INCOMPLETE"
          },
          {
            id: 4,
            title: "Enrollment status",
            description: "",
            destination: ({ name: ""}),
            style: "checklist-row_num last-step",
            typename: "EARNINGS_STATUS",
            status: "INCOMPLETE"
          },
        ]
      }
    },
  methods: {
    isMobile() {
      return checklistMethods.isMobile();
    },
    isBtnDisabled(card) {
        
        // console.log(this.currentStep, "current step")
        if(card.typename == sessionStorage.getItem('cardType')){
            return false;
        }else{
            return true;
        }
        // return checklistMethods.isBtnDisabled(card, this.currentStep, this.farthestStep);
      },

      redirectPages(status,insruanceExpiryFlag,ocrFlag){
         if(status == "Payment Agreement Pending"){ 
           proxyApi.get('/checkPaymentRaw')
            .then(async ({data}) => {
              if (data.rowCount > 0) { 
                if(data.result[0].response_text == "Created"){
                  sessionStorage.setItem("cardType","PERSONAL_DETAILS")
                  sessionStorage.setItem("progress","PERSONAL_DETAILS")
                  sessionStorage.setItem("OCR_STATUS", "processing")
                }
              }
            })
        }
      
      if(status == 'Payment Agreement Complete'){
        proxyApi.get('/getDriverKYCstatus')
				.then(async ({data}) => {
					if (data.rowCount > 0) { 
            if(data.result.onboard_status == 'payable'){
              sessionStorage.setItem("cardType","INSURANCE_UPLOAD")
              sessionStorage.setItem("progress","INSURANCE_UPLOAD")
              sessionStorage.setItem("OCR_STATUS", "in-process")
            }else{
              sessionStorage.setItem("cardType","PERSONAL_DETAILS")
              sessionStorage.setItem("progress","PERSONAL_DETAILS")
              sessionStorage.setItem("OCR_STATUS", "processing")
            }
					} 
				})
      }
       
      if(status == 'OCR Failed' || status == 'OCR Clear' || status == 'Checkr Package Submitted' || status == 'Checkr Invitation Sent'){
        if(status == 'OCR Failed'){
          sessionStorage.setItem("cardType","INSURANCE_UPLOAD")
          sessionStorage.setItem("progress","INSURANCE_UPLOAD")
          if(sessionStorage.getItem('callingPage') == 'InsUpload'){
            sessionStorage.setItem("OCR_STATUS", "in-process")
          }else{
            sessionStorage.setItem("OCR_STATUS", "failed")
          }
          
        }else{
          sessionStorage.setItem("cardType","BACKGROUND_CHECK")
          sessionStorage.setItem("progress","BACKGROUND_CHECK")
          sessionStorage.setItem("OCR_STATUS", "clear")
        }
        proxyApi.get('/getDriverKYCstatus')
				.then(async ({data}) => {
					if (data.rowCount > 0) { 
            if(data.result.onboard_status != 'payable'){
              sessionStorage.setItem("cardType","PERSONAL_DETAILS")
              sessionStorage.setItem("progress","PERSONAL_DETAILS")
              sessionStorage.setItem("OCR_STATUS", "processing")
            }
					} 
				})
      }
      if(status == 'Insurance Image Invalid'){
        sessionStorage.setItem("cardType","INSURANCE_UPLOAD")
          sessionStorage.setItem("progress","INSURANCE_UPLOAD")
          sessionStorage.setItem("OCR_STATUS", "failed")
      }
      if(status == 'Withdrawn' || status == 'Checkr Package Denied' || status == 'Checkr Package Suspended'){
        sessionStorage.setItem("cardType","EARNINGS_STATUS")
          sessionStorage.setItem("progress","EARNINGS_STATUS")
          sessionStorage.setItem("OCR_STATUS", "Withdrawn")
      }
      if(status == 'Application Waitlist'){
          sessionStorage.setItem("cardType","EARNINGS_STATUS")
          sessionStorage.setItem("progress","EARNINGS_STATUS")
          sessionStorage.setItem("ocrFlag",ocrFlag);
          
         if(insruanceExpiryFlag == 'Expired'){
          sessionStorage.setItem("OCR_STATUS", "insurance_expired")
         }else{
          sessionStorage.setItem("OCR_STATUS", "Add to waitlist")
         }
          
      }
      if (status == 'Approved Manual' || status == 'Approved Auto') {
        if(ocrFlag == false){
          sessionStorage.setItem("cardType","EARNINGS_STATUS")
          sessionStorage.setItem("progress","EARNINGS_STATUS")
          sessionStorage.setItem("ocrFlag",ocrFlag);
          sessionStorage.setItem("driverStatus", "approved_expired");
        }
        
        if(insruanceExpiryFlag == 'Expired'){
          sessionStorage.setItem("cardType","EARNINGS_STATUS")
          sessionStorage.setItem("progress","EARNINGS_STATUS")
          sessionStorage.setItem("OCR_STATUS", "insurance_expired");
          sessionStorage.setItem("driverStatus", "approved_expired");
        }
				
			}
     
      let ocrstatus = sessionStorage.getItem("OCR_STATUS");
          console.log(ocrstatus)
          if(ocrstatus){
            this.ocr_status = ocrstatus;
          }

          let ocrFlagSession = sessionStorage.getItem("ocrFlag");
          if(ocrFlagSession == "false"){
            this.ocr_flag = false;
          }

          this.processingStatus = ocrstatus;
          
          let driverStatus = sessionStorage.getItem("driverStatus");
          if(driverStatus == "approved_expired"){
            this.driver_status = true;
          }
    

          this.cardGroupData.map((item) => {
                if(item.typename == sessionStorage.getItem("cardType")){
                  // console.log(item.id)
                  this.cardGroupData[item.id - 1].description = "true";
                }

                if(this.ocr_status == 'failed' || this.ocr_status == 'insurance_expired' || !this.ocr_flag){
                  sessionStorage.setItem("referer","enrollment-checklist")
                  this.cardGroupData[item.id - 1].destination = ({ name: "UploadCardDetails"});
                }

                if(item.title == 'Enrollment status'){
                  if(this.ocr_status == 'Withdrawn'){
                    item.style = "checklist-row_num last-step errorCross";
                  }
                  if(this.ocr_status == 'insurance_expired'){
                    item.style = "checklist-row_num last-step errorWarning";
                  }
                }
            })
            this.isLoading = false;
  },
       
      
  },
  mounted() {
    this.isLoading = true;
    proxyApi.get('/getUpdatedStatus').then(async (data) => {
        let resData = data.data.result[0];
        let today = new Date();
            let formattedDateToday = today.toISOString().split('T')[0];
            let insuranceExpiryDate = resData.insuranceExpiry;
            let insruanceExpiryFlag = "";
            if(insuranceExpiryDate < formattedDateToday ){
              insruanceExpiryFlag = "Expired";
            }else{
              insruanceExpiryFlag = "";
            }
            let ocrFlag = resData.insurance_verified;
            this.redirectPages(resData.candidateStatus,insruanceExpiryFlag,ocrFlag);
      })

      // let ocrstatus = sessionStorage.getItem("OCR_STATUS");
      //     console.log(ocrstatus)
      //     if(ocrstatus){
      //       this.ocr_status = ocrstatus;
      //     }

      //     let ocrFlagSession = sessionStorage.getItem("ocrFlag");
      //     if(ocrFlagSession == "false"){
      //       this.ocr_flag = false;
      //     }

      //     this.processingStatus = ocrstatus;
          
      //     let driverStatus = sessionStorage.getItem("driverStatus");
      //     console.log(driverStatus,"driverStatus")
      //     if(driverStatus == "approved_expired"){
      //       this.driver_status = true;
      //     }
    

      //     this.cardGroupData.map((item) => {
      //           if(item.typename == sessionStorage.getItem("cardType")){
      //             // console.log(item.id)
      //             this.cardGroupData[item.id - 1].description = "true";
      //           }

      //           if(this.ocr_status == 'failed' || this.ocr_status == 'insurance_expired' || !this.ocr_flag){
      //             sessionStorage.setItem("referer","enrollment-checklist")
      //             this.cardGroupData[item.id - 1].destination = ({ name: "UploadCardDetails"});
      //           }

      //           if(item.title == 'Enrollment status'){
      //             if(this.ocr_status == 'Withdrawn'){
      //               item.style = "checklist-row_num last-step errorCross";
      //             }
      //             if(this.ocr_status == 'insurance_expired'){
      //               item.style = "checklist-row_num last-step errorWarning";
      //             }
      //           }
      //       })
    
         
   
    
  }
});


  import { state } from "@/harlem/stores";
  import { proxyApi } from "@/http/http.config";
  import router from "@/router";
  import { setStatus } from "@/services/stage.service";
  import { encodeWithParam } from "@/util/DataEncodeDecode";
  import { defineComponent, ref, onMounted } from "vue";
  import checklistMethods from "../components/Checklist/Helpers/Index";
  export default defineComponent({
    name: "LocationUpdateCa",
    setup() {
      const domainURL = ref("");
      onMounted(async () => {
        proxyApi.post("/validateLocationStatus", encodeWithParam({ clientLocationID: state.user.locationId})).then(async(data: any) => {
          const isRecruiting = data.data.result[0].isRecruiting;
          let thresholdCheck:any= await proxyApi.post("/thresholdCheck", encodeWithParam({ clientLocationID: state.user.locationId }));
          if (isRecruiting && thresholdCheck.data.allowSignup) {
            // proxyApi.post('/addApplicationStatus', encodeWithParam({ stepName: 'ELIGIBILITY', stepStatus: 'INCOMPLETE' }));
            // setStatus("ELIGIBILITY", "INCOMPLETE")
            proxyApi.post('/updateStatus', encodeWithParam({ stepName: 'Personal Details', status: 'Personal Details Pending' }));
            setStatus("Personal Details", "Personal Details Pending")
  
            if (checklistMethods.isMobile()) {
              sessionStorage.setItem("statusWarningMessage", "Personal information incomplete");
               router.push({
                name: "ca-dashboard",
              });
            } else {
              router.push({
                name: "fullProfileDetails",
              });
            }
          } else {
            domainURL.value = `${location.origin}/login`
          }
        })
  
      })
      return { domainURL }
    }
  })
  
  
import router from "@/router";
import { encode } from "@/util/DataEncodeDecode";

const checklistMethods = {
  getIndex(label) {
    switch (label) {
      case 'PERSONAL_DETAILS':
        return 1;
      // case 'DOCUMENTS':
      //   return 2;
      case 'INSURANCE_UPLOAD':
        return 2;
      case 'EARNINGS_ACCOUNT':
        return 3;
      default: return 1
    }
  },

  getIndexForStepper(label) {
    switch (label) {
      case 'PERSONAL_DETAILS':
        return 1;
      case 'INSURANCE_UPLOAD':
        return 2;
      case 'BACKGROUND_CHECK':
        return 3; 
      case 'EARNINGS_STATUS':
        return 4;
      default: return 1
    }
  },

  isMobile() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return true
    } else {
      return false
    }
  },
  isBtnDisabled(card, currentStep, farthestStep) {
    if (currentStep.label === card.typename) {
      return false;
    } else return true
  },
  removeBackBtn() {
    const path = window.location.pathname
    return (path !== "/signup/checklist/eligibility" && path !== "/signup/checklist/documents")
  },
  nextRouteOnMobile(query) {
    const path = window.location.pathname
    if (path.toLowerCase() == "/signup/checklist/personaldetails") {
      router.push({ name: "personalDetails", query: { ...query } })
    } else if (path == "/signup/checklist/documents") {
      router.push({ name: "documents", query: { ...query } })
    } else if (path.toLowerCase() == "/signup/checklist/insuranceupload") {
      router.push({ name: "insuranceUpload", query: { ...query } })
    } else if (path.toLowerCase() == "/signup/checklist/earningsaccount") {
      router.push({ name: "earningsAccount", query: { ...query } })
    }
  },
  checklistRouter(getFarthestStep, currentStep) {
    const urlPath = window.location.pathname

    const lowercaseUrlPath = urlPath?.toLowerCase();
    console.log(lowercaseUrlPath)
    const isPersonalDetailStep = ["/signup/personaldetails", "/signup/checklist/personaldetails", "/signup/confirmation"]?.includes(lowercaseUrlPath)
    const isDocumentsStep = ["/signup/documents", "/signup/checklist/documents", "/signup/signature"]?.includes(lowercaseUrlPath)
    const isEarningsAccountStep = (urlPath.toLowerCase() === "/signup/earningsaccount" || urlPath.toLowerCase() === "/signup/checklist/earningsaccount")
    const isInsuranceUploadStep = (urlPath.toLowerCase() === "/signup/insuranceupload" || urlPath.toLowerCase() === "/signup/checklist/insuranceupload")
    if (getFarthestStep == null) {
      localStorage.setItem("step",(JSON.stringify(1)))
    }
    if (isPersonalDetailStep) {
      currentStep.label = "PERSONAL_DETAILS";
      currentStep.stepIndex = 1
      localStorage.setItem("step", JSON.stringify(Math.max(currentStep.stepIndex, getFarthestStep)))
    } else if (isDocumentsStep) {
      currentStep.label = "DOCUMENTS";
      currentStep.stepIndex = 2
      localStorage.setItem("step", JSON.stringify(Math.max(currentStep.stepIndex, getFarthestStep)))
    } else if (isInsuranceUploadStep) {
      currentStep.label = "INSURANCE_UPLOAD";
      currentStep.stepIndex = 3
      localStorage.setItem("step", JSON.stringify(Math.max(currentStep.stepIndex, getFarthestStep)))
    } else if (isEarningsAccountStep) {
      currentStep.label = "EARNINGS_ACCOUNT";
      currentStep.stepIndex = 4
      localStorage.setItem("step", JSON.stringify(Math.max(currentStep.stepIndex, getFarthestStep)))
    }
  }
}

export default checklistMethods;
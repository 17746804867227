
import { state } from '@/harlem/stores'
import { useRouter } from 'vue-router'
import { defineComponent, ref, computed, onMounted, reactive } from "vue";


export default defineComponent({
    name: "ReviewEdit",
    components: {
      
    },
    setup() {
         
        const router = useRouter()
        const goFullPII = () => {
            router.push({ name: 'fullProfileDetails' })

        }

        return {
            goFullPII,
        }
    }
});

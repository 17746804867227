
  import { defineComponent,onMounted } from "vue";
  import { useRoute, useRouter } from "vue-router";
//   import { state } from "@/harlem/stores";
//   import { gTagEvent } from "@/util/GoogleAnalytics";
  
  const router = useRouter();
  export default defineComponent ({
    name: "LandingPage",
   setup() {
      const route = useRoute();
      const router = useRouter();
      const locationId = route.query.locationId;
      let locname=route.query.name
      let locationName ;
      if(typeof locname ==='string'){
         locationName =atob(locname)
      }
      // console.log(locationName,"fin output")
      // const campaign = route.query?.campaign || null;
      // const medium = route.query?.medium || null;
      localStorage.clear();
      sessionStorage.removeItem("signupData");
          const enrollNow = () => {
              router.push({ name: 'PhoneVerification',
        query: {
              locationId: locationId,
              name: locationName,
            }
          })
          }
      // const urlCheck=()=>{
      //   if(!route.query['locationId'] && !route.query['name']){
      //    return router.push({ name: "login" });
      //   }
      //   if(!route.query['name']){
      //     return router.push({ name: "login" });
      //   }
      // }
  
      // onMounted(() => {
      //   urlCheck();
      // });
  
      return {
                enrollNow
      }
  },
  });
  
import { createLogger } from "vue-logger-plugin";
// import config from "@/config";
// create logger with options
const logger = createLogger({
  enabled: true,
  level:process.env.VUE_APP_LOGGING_LEVEL ||
    "error",
});

export default logger;


  import IconArrowRight from "../../../components/icons/IconArrowRight.vue";
  import IconCheck from "../../../components/icons/IconCheck.vue";
  import { defineComponent } from "vue";
  import checklistMethods from "../components/Checklist/Helpers/Index";
  import { state } from "../../../harlem/stores";
  import "../../../assets/css/components/checklist.scss"
  import router from "@/router";
  // const getStep = JSON.parse(localStorage.getItem("progress") || "{}").step;
  const getStep = sessionStorage.getItem("progress");
  const getStepStatus = JSON.parse(localStorage.getItem("progress") || "{}").status;
  const getFarthestStep = JSON.parse(localStorage?.getItem("step") || "{}");
  
  export default defineComponent({
    name: "checklist",
    components: {
      IconCheck,
      IconArrowRight
    },
    props: ['step', 'stepIndex'],
    data() {
      return {
        state,
        lock1Step: JSON.parse(localStorage?.getItem("step") || "{}") > 1 ? true : false,
        farthestStep: JSON.parse(localStorage?.getItem("step") || "{}"),
        currentStep: {
          label: sessionStorage.getItem("progress"),
          stepIndex: checklistMethods.getIndex(sessionStorage.getItem("progress")),
          status: JSON.parse(localStorage.getItem("progress") || "{}").status,
        },
        cardGroupData: [
          {
            id: 1,
            title: "Personal Information",
            destination: ({ name: "fullProfileDetails"}),
            style: "checklist-row_num first-step",
            typename: "PERSONAL_DETAILS",
            status: "INCOMPLETE"
          },
          {
            id: 2,
            title: "Upload vehicle information and car insurance",
            destination: ({ name: "UploadCardDetails"}),
            style: "checklist-row_num",
            typename: "INSURANCE_UPLOAD",
            status: "INCOMPLETE"
          },
          {
            id: 3,
            title: "Earnings Account",
            destination: ({ name: "PersonalDirectDeposit"}),
            style: "checklist-row_num",
            typename: "EARNINGS_ACCOUNT",
            status: "INCOMPLETE"
          },
        ]
      }
    },
    methods: {
      isMobile() {
        return checklistMethods.isMobile();
      },
      isBtnDisabled(card) {
        
        // console.log(this.currentStep, "current step")
        if(card.typename == sessionStorage.getItem('cardType')){
            return false;
        }else{
            return true;
        }
        // return checklistMethods.isBtnDisabled(card, this.currentStep, this.farthestStep);
      },
      nextRouteOnMobile() {
        if(sessionStorage.getItem('cardType') == 'PERSONAL_DETAILS'){
            return router.push({
                name: "fullProfileDetails",
              });
        }
        if(sessionStorage.getItem('cardType') == 'INSURANCE_UPLOAD'){
            return router.push({
                name: "UploadCardDetails",
              });
        }
        if(sessionStorage.getItem('cardType') == 'EARNINGS_ACCOUNT'){
            return router.push({
                name: "PersonalDirectDeposit",
              });
        }
        // return checklistMethods.nextRouteOnMobile(this.$route.query);
      }
    },
    mounted() {
      console.log(sessionStorage.getItem('cardType'), "card type",this.currentStep.stepIndex);
      console.log(JSON.parse(localStorage.getItem("progress") || "{}").step);
        // if(sessionStorage.getItem('cardType') == 'EARNINGS_ACCOUNT'){
        //     this.currentStep.stepIndex = 3;
        //     this.currentStep.status = "INCOMPLETE";

        //     this.currentStep.stepIndex = 2;
        //     this.currentStep.status = "COMPLETE";

        //     this.currentStep.stepIndex = 1;
        //     this.currentStep.status = "COMPLETE";
        // }

        // if(sessionStorage.getItem('cardType') == 'INSURANCE_UPLOAD'){
        //     this.currentStep.stepIndex = 2;
        //     this.currentStep.status = "INCOMPLETE";

        //     this.currentStep.stepIndex = 3;
        //     this.currentStep.status = "INCOMPLETE";

        //     this.currentStep.stepIndex = 1;
        //     this.currentStep.status = "COMPLETE";
        // }

        // if(sessionStorage.getItem('cardType') == 'PERSONAL_DETAILS'){
        //     this.currentStep.stepIndex = 2;
        //     this.currentStep.status = "INCOMPLETE";

        //     this.currentStep.stepIndex = 3;
        //     this.currentStep.status = "INCOMPLETE";

        //     this.currentStep.stepIndex = 1;
        //     this.currentStep.status = "INCOMPLETE";
        // }
        
        
      checklistMethods.checklistRouter(JSON.parse(localStorage?.getItem("step") || "{}"), this.currentStep)
    },
  })
  